/**
 * SideBar du site
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { testDroit } from '../../Utils/testDroit';

import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent } from 'react-pro-sidebar';

import * as fonctionSidebar from '../../../fonctionsRoles/sidebar';
const SideBar = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const idUser = user[usurpation] ? user[usurpation].fk_id : '';
    const [toggled, setToggled] = useState(false);
    const color = process.env.REACT_APP_ENVIRONNEMENT === 'TEST' ? '#881C1C' : '';
    const [previousPath, setPreviousPath] = useState();
    const menuDeGestion = [
        {
            to: '/liste-gestion-absences',
            label: 'Gestion des absences',
            roleFonction: fonctionSidebar.Liste_des_absences,
            module: 'absences',
        },
        {
            to: '/liste-gestion-frais',
            label: 'Gestion des frais',
            roleFonction: fonctionSidebar.Liste_des_frais,
            module: 'frais',
        },
        {
            to: '/liste-informations',
            label: 'Informations',
            roleFonction: fonctionSidebar.Liste_des_informations,
            module: 'default',
        },
        { to: '/liste-livret', label: 'Livrets', roleFonction: fonctionSidebar.Liste_des_model_de_livrets, module: 'livret' },
        {
            to: '/liste-cotisations',
            label: 'Cotisations & règlements',
            roleFonction: fonctionSidebar.Cotisation_réglement,
            module: 'cotisation',
        },
        {
            to: '/liste-reglements',
            label: 'État des règlements',
            roleFonction: fonctionSidebar.Etat_des_réglements,
            module: 'cotisation',
        },
        {
            to: '/liste-presence',
            label: 'liste des présences',
            roleFonction: fonctionSidebar.Liste_presences,
            module: 'presences',
        },
    ];
    const listeUtilisateur = [
        {
            to: '/liste-responsables',
            label: 'Responsables',
            roleFonction: fonctionSidebar.Liste_responsables,
            module: 'default',
        },
        { to: '/liste-eleves', label: 'Élèves', roleFonction: fonctionSidebar.Liste_élèves, module: 'default' },
        { to: '/liste-agents', label: 'Agents', roleFonction: fonctionSidebar.Liste_Agent, module: 'default' },
    ];
    const scolarite = [
        {
            to: '/liste-ateliers',
            label: 'Scolarité',
            roleFonction: fonctionSidebar.Liste_Atelier,
            module: 'default',
        },
    ];
    const planning = [
        {
            to: '/planning-salle',
            label: 'Salle',
            roleFonction: fonctionSidebar.Salle,
            module: 'planning',
        },
    ];
    const gestionSite = [
        { to: '/liste-parametre', label: 'Paramètres', roleFonction: fonctionSidebar.Paramétre, module: 'gestionSite' },
        { to: '/viewTable', label: 'Gestion base de donnée', roleFonction: fonctionSidebar.Gestion_BDD, module: 'gestionBDD' },
        {
            to: '/liste-groupe-role',
            label: 'Gestion des rôles',
            roleFonction: fonctionSidebar.Gestion_de_rôle,
            module: 'gestionSite',
        },
        {
            to: '/inscription/accueil',
            label: 'Model inscription',
            roleFonction: fonctionSidebar.Model_inscription,
            module: 'default',
        },
    ];
    const profilT = [
        {
            to: user[usurpation]
                ? user[usurpation].roleFonction === 'Responsable'
                    ? `/fiche-responsable/${idUser}`
                    : `/fiche-agent/${idUser}`
                : '',
            label: 'Mon Profil',
            roleFonction: fonctionSidebar.Mes_profil,
            module: 'default',
        },
        {
            to: `/service/${idUser}`,
            label: 'Mes services',
            roleFonction: fonctionSidebar.Mes_services,
            module: 'services',
        },
        {
            to: `/mes-absences/${idUser}`,
            label: 'Mes absences',
            roleFonction: fonctionSidebar.Mes_absences,
            module: 'absences',
        },
        {
            to: `/listeFrais-agent/${idUser}`,
            label: 'Mes frais',
            roleFonction: fonctionSidebar.Mes_frais,
            module: 'frais',
        },
        {
            to: `/mes-cours/${idUser}`,
            label: 'Mes cours',
            roleFonction: fonctionSidebar.Mes_cours,
            module: 'default',
        },
    ];

    function SousMenu(props) {
        const baliseMenu = [];
        if (user[usurpation]) {
            for (let index = 0; index < props.menu.length; index++) {
                if (process.env.REACT_APP_ACCESS_MODULE) {
                    if (
                        (testDroit(user[usurpation].roleFonction, props.menu[index].roleFonction) ||
                            props.menu[index].roleFonction === undefined) &&
                        process.env.REACT_APP_ACCESS_MODULE.split(',').includes(props.menu[index].module)
                    ) {
                        baliseMenu.push(
                            <MenuItem>
                                <NavLink
                                    activeClassName="selected"
                                    to={props.menu[index].to}
                                    onClick={() => {
                                        handleToggleSidebar(false);
                                    }}
                                >
                                    {props.menu[index].label}
                                </NavLink>
                            </MenuItem>,
                        );
                    }
                } else {
                    if (
                        testDroit(user[usurpation].roleFonction, props.menu[index].roleFonction) ||
                        props.menu[index].roleFonction === undefined
                    ) {
                        baliseMenu.push(
                            <MenuItem>
                                <NavLink
                                    activeClassName="selected"
                                    to={props.menu[index].to}
                                    onClick={() => {
                                        handleToggleSidebar(false);
                                    }}
                                >
                                    {props.menu[index].label}
                                </NavLink>
                            </MenuItem>,
                        );
                    }
                }
            }
        }
        return props.label && baliseMenu.length > 0 && user[usurpation].role !== 'Responsable' ? (
            <SubMenu title={props.label}>{baliseMenu}</SubMenu>
        ) : (
            baliseMenu
        );
    }
    function handleToggleSidebar(event) {
        if (event === undefined) {
            event = !props.toggled;
        }
        return props.handleToggleSidebar(event);
    }
    if (!previousPath) {
        setPreviousPath(window.location.pathname);
    }
    if (previousPath !== window.location.pathname) {
        props.resetPagination();
        props.reponseRecherche();
        setPreviousPath(window.location.pathname);
    }
    useEffect(() => {
        if (props.toggled === true) {
            setToggled(true);
        } else if (props.toggled === false) {
            setToggled(false);
        }
    }, [props.toggled]);
    return (
        <div className="containerSidBar">
            <ProSidebar onToggle={() => handleToggleSidebar()} toggled={toggled} breakPoint="md">
                <Menu iconShape="square" style={{ backgroundColor: color }}>
                    <SidebarHeader>
                        <MenuItem className="menuItem"></MenuItem>
                    </SidebarHeader>
                    <SidebarContent
                        className="sidebarContent"
                        onClick={() => {
                            handleToggleSidebar(false);
                        }}
                    >
                        <MenuItem>
                            <Link to="/accueil">
                                <span>Accueil</span>
                            </Link>
                        </MenuItem>
                    </SidebarContent>
                    {SousMenu({ menu: listeUtilisateur, label: 'Listes' })}
                    {SousMenu({ menu: scolarite })}
                    {SousMenu({ menu: menuDeGestion, label: 'Gestion' })}
                    {SousMenu({ menu: planning, label: 'Planning' })}
                    {SousMenu({ menu: gestionSite, label: 'Gestion du site' })}
                    {SousMenu({ menu: profilT, label: 'Mon Compte' })}
                </Menu>
            </ProSidebar>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        reponseRecherche() {
            dispatch({ type: 'removeRecherche' });
        },
        resetPagination() {
            dispatch({ type: 'resetPagination' });
        },
    };
}
export default connect(null, mapDispatchToProps)(SideBar);
