/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

function Reduire(props) {
    const css = useStylesMaterielUIGlobal();
    const [CSSDisplay, setCSSDisplay] = useState('block');
    const [icone, setIcone] = useState(<ArrowDropUpIcon className={css.fontSize2em} />);
    function reduire() {
        if (CSSDisplay === 'none') {
            setCSSDisplay('block');
            setIcone(<ArrowDropUpIcon className={css.fontSize2em} />);
        } else {
            setCSSDisplay('none');
            setIcone(<ArrowDropDownIcon className={css.fontSize2em} />);
        }
    }
    useEffect(() => {
        setCSSDisplay(props.none ? 'none' : 'block');
        setIcone(
            props.none ? <ArrowDropDownIcon className={css.fontSize2em} /> : <ArrowDropUpIcon className={css.fontSize2em} />,
        );
    }, [props.none]);
    return (
        <div className="flex ">
            <div>
                <IconButton
                    className={[css.none, css.xs_block, css.xs_p0, css.xs_floatRight, css.xs_mr1].concat(props.className)}
                    onClick={() => reduire()}
                >
                    {icone}
                </IconButton>
            </div>
            {CSSDisplay === 'block' ? (
                <div style={{ display: CSSDisplay }} className="w100">
                    {props.children}
                </div>
            ) : (
                <div>
                    <h4>{props.titreDisplayNone}</h4>
                </div>
            )}
        </div>
    );
}

export default Reduire;
