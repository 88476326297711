/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Button, IconButton, Tabs, Tab } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/ImageOutlined';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import { GET, POST } from '../../../components/Utils/requete';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import TempCoursFormat from '../../../components/organism/MiseEnFormeTempsCours/TempCoursFormat';
import DateHeureFormat from '../../../components/Utils/DateHeureFormat';
import downloadFile from '../../../components/Utils/downloadFile';
import CardComponent from '../../../components/organism/card/card';
import TabPanel from '../../../components/organism/Onglet/TabPanel';
import ModuleDisciplineView from '../../livret/module/ModuleDisciplineView';
import ModuleValidationView from '../../livret/module/ModuleValidationView';
import ModuleGeneralView from '../../livret/module/ModuleGeneralView';
import anneeEnCour_Inscription from '../../../components/Utils/requeteParametre/anneeEnCour_Inscription';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { tableHeadScolariteInterne, tableHeadAtelier, acceptedRolesConsult, input } from '../const';

const ViewEleve = (props) => {
    let { id } = useParams();
    const url = props.location.search.split('');
    const urlComplete = props.location.pathname + props.location.search;
    url.shift();
    const selectView = url.join('');
    const user = useSelector((state) => state.Utilisateur);
    const donneeslivret = useSelector((state) => state.LivretDonnees);
    const css = useStylesMaterielUIGlobal();
    const usurpation = user.userC;
    const [dataEleve, setDataEleve] = useState({ SI: [], atelier: [] });
    const [dataFiltre, setDataFiltre] = useState({
        anneeScolaire: [],
    });
    const [value, setValue] = useState(0);
    const [viewModule, setViewModule] = useState([]);

    function notification(note) {
        props.notification({ message: note.message, status: note.status });
    }

    function CertificatMedical(props) {
        const verifAnneeScolaire = dataFiltre.anneeScolaire.filter((e) => +e.id === +props.anneeScolaire);
        if (props.certificatObligatoire && verifAnneeScolaire.length > 0) {
            if (props.fichier) {
                return (
                    <div className="flex directionColumn mt2">
                        <span> Certificat médical: </span>
                        <span>
                            <Link
                                to={urlComplete}
                                onClick={() => {
                                    downloadFile(
                                        'certificatMedical',
                                        {
                                            fk_scolariteInterne: props.idScolarite,
                                            fk_responsable: user[usurpation].fk_id,
                                        },
                                        props.fichier,
                                    );
                                }}
                            >
                                <ImageIcon fontSize="small" />
                                {props.fichier}
                            </Link>
                        </span>
                    </div>
                );
            } else {
                return (
                    <>
                        <input
                            id="raised-button-file"
                            type="file"
                            hidden
                            onChange={(event) => uploadCertificatMedicalSI(event.target.files[0], props.idScolarite)}
                        />
                        <label htmlFor="raised-button-file">
                            <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                component="span"
                                className={[css.mt2, css.mb2, css.fontSize0_7em]}
                            >
                                Ajouter un certificat medical
                            </Button>
                        </label>
                    </>
                );
            }
        } else {
            return <></>;
        }
    }

    function ViewLibelleOffre(props) {
        let libelle = props.offre;
        if (props.nomStyle) {
            libelle = `${props.offre} - ${props.nomStyle} ${props.style}`;
        }
        return <span>{libelle} </span>;
    }

    function ViewAnneeDateCreation(props) {
        const css = window.matchMedia('(max-width: 576px)').matches ? 'flex fontSize0_7em ' : 'flex fontSize0_8em ';
        return (
            <>
                <span className={css}>
                    <span>Année Scolaire :</span>
                    <span>{props.annee}</span>
                </span>
                <span className={css}>
                    <span>Date d'inscription :</span>
                    <span>{DateHeureFormat({ date: props.date })}</span>
                </span>
            </>
        );
    }

    function Atelier(atelier) {
        atelier = atelier.split('|');
        if (atelier[0] !== '' && acceptedRolesConsult.includes(user[usurpation].role)) {
            return (
                <Link to={`/fiche-atelier/${atelier[0]}`} className={css.linkW100MR2}>
                    <p
                        className="textCenter cWhite pr0_4rem pl0_4rem radius4"
                        style={{ backgroundColor: 'lightGray', color: 'black' }}
                    >
                        {atelier[1] && atelier[1] !== '' ? atelier[1] : 'Atelier'}
                    </p>
                </Link>
            );
        } else {
            return <></>;
        }
    }

    function Ateliers(props) {
        if (props.ateliers.length > 0) {
            return props.ateliers.map((atelier) => Atelier(atelier));
        } else {
            return <></>;
        }
    }

    function Enseignants(props) {
        if (props.enseignants) {
            return props.enseignants.split('|-|').map((enseignant) => (
                <>
                    <>{enseignant.split('|')[1]}</>
                    <br />
                </>
            ));
        } else return <></>;
    }

    function dataView(data, module) {
        let element = [];
        let moduleView;
        switch (module) {
            case 'general':
                if (data.general) {
                    moduleView = <ModuleGeneralView data={data.general} />;
                    element.push(moduleView);
                }
                break;
            case 'discipline':
                if (data.discipline) {
                    data.discipline.map((row, index) => {
                        moduleView = <ModuleDisciplineView key={index} data={row} />;
                        return element.push(moduleView);
                    });
                }
                break;
            case 'validation':
                if (data.validation) {
                    moduleView = <ModuleValidationView data={data.validation} />;
                    element.push(moduleView);
                }
                break;
            default:
                break;
        }

        setViewModule(element);
    }

    async function uploadCertificatMedicalSI(file, idScolariteUpdate) {
        if (file.size > 2097152) {
            alert('Votre fichier est trop gros, il ne doit pas dépasser 2Mo');
            return;
        }
        const data = new FormData();
        data.append('file', file);
        data.append('fk_scolariteInterne', idScolariteUpdate);
        data.append('fk_responsable', user[usurpation].fk_id);
        const response = await POST('/api/fichiers/upload/certificatMedical', data, {
            upload: true,
            redirectOnError: false,
        });
        if (response.success) {
            notification({ message: 'Les informations ont été mise à jour.', status: true });
            recupDataEleve();
        }
    }
    async function recupDataEleve() {
        const tableauData = dataEleve;
        let response = await GET('/api/eleves/' + id + '/SI_list');
        tableauData.SI = response.data;
        response = await POST('/api/eleves/' + id + '/listAteliers', {
            fk_anneeScolaire: user.idAnneeScolaire,
            visibilite: 3,
        });

        tableauData.atelier = response.data;
        setDataEleve(tableauData);
    }
    async function getData(idLivret, idScolarite) {
        const response = await GET(`/api/livrets/${idLivret}/${idScolarite}`);
        props.getdata(response.data);
        setViewModule(<ModuleGeneralView data={response.data.general} />);
    }

    async function anneeScolaire() {
        const data = await anneeEnCour_Inscription();
        setDataFiltre((prevState) => ({
            ...prevState,
            anneeScolaire: data,
        }));
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        recupDataEleve();
        anneeScolaire();
    }, [id]);
    switch (selectView) {
        case 'scolariteInterne':
            return (
                <div className=" ml7 mt5 mr7 overflowAuto h80" style={{ opacity: '0.9' }}>
                    <CardComponent className={[css.pl1, css.pr1, css.pb1]}>
                        <h4 className="textCenter mt2 ">Scolarité Conservatoire</h4>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeadCustom data={tableHeadScolariteInterne}></TableHeadCustom>
                                <TableBody>
                                    {dataEleve.SI.map((row, index) => (
                                        <TableRow className={css.trbody} key={index}>
                                            <TableCell
                                                className={[css.td].concat(
                                                    window.matchMedia('(max-width: 576px)').matches ? [] : [css.w20],
                                                )}
                                                component="th"
                                                scope="row"
                                            >
                                                <div>Année scolaire</div>
                                                <ViewAnneeDateCreation
                                                    annee={row.libelleAnneeScolaire}
                                                    date={row.dateCreation}
                                                />
                                                <div className="flex">
                                                    <Ateliers ateliers={row.ateliers ? row.ateliers.split('|-|') : []} />
                                                </div>
                                                <CertificatMedical
                                                    fichier={row.certificatMedical}
                                                    idScolarite={row.idScolarite}
                                                    certificatObligatoire={row.certificatObligatoire}
                                                    anneeScolaire={row.fk_anneeScolaire}
                                                />
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Livret</div>
                                                {row.statutLivret === 2 ? (
                                                    <Link
                                                        to={`/viewEleve/${id}?livret`}
                                                        onClick={() => getData(row.fk_livret, row.idScolarite)}
                                                    >
                                                        <IconButton className={css.p0}>
                                                            <MenuBookIcon style={{ color: '#8dae51' }} />
                                                        </IconButton>
                                                    </Link>
                                                ) : null}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Site</div>
                                                {row.libelleSite}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Offre</div>
                                                <ViewLibelleOffre
                                                    offre={row.libelleOffre}
                                                    nomStyle={row.libelleNomStyle}
                                                    style={row.libelleStyle}
                                                />
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                {row.precisionOffre ? <div>Précision offre</div> : null}
                                                {row.precisionOffre}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                {row.fk_cycle ? <div>Précision FM-G</div> : null}
                                                {row.fk_cycle === null ? '' : `Cycle ${row.fk_cycle}/ Année ${row.fk_annee}`}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Statut</div>
                                                {row.libelleStatut}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Enseignant</div>
                                                {row.nomPrenomEnseignant}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                {row.commentaireInscription ? <div>Commentaire à l'inscription</div> : null}
                                                {row.commentaireInscription}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardComponent>
                    <CardComponent className={[css.mt2, css.pl1, css.pr1, css.pb1]}>
                        <h4 className="textCenter mt2 mb2">Cours de l'élève</h4>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeadCustom data={tableHeadAtelier}></TableHeadCustom>
                                <TableBody>
                                    {dataEleve.atelier.map((row, index) => (
                                        <TableRow className={css.trbody} key={index}>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Type</div>
                                                {row.libelleType}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Site</div>
                                                {row.libelleSite}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                {row.libelleSalle ? (
                                                    <>
                                                        <div>Salle</div>
                                                        {row.libelleSalle}
                                                    </>
                                                ) : null}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>jour</div>
                                                <TempCoursFormat
                                                    heureDebut={row.heureDebut}
                                                    heureFin={row.heureFin}
                                                    jour={row.jour}
                                                />
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Enseignant</div>
                                                <Enseignants enseignants={row.enseignants} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardComponent>
                </div>
            );
        case 'livret':
            return (
                <div className=" ml10 mt5 mr10 overflowAuto h80" style={{ opacity: '0.9' }}>
                    <CardComponent>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            className={[css.positionFixed, css.w80]}
                        >
                            <Tab label="general" onClick={() => dataView(donneeslivret, 'general')} />
                            <Tab
                                label="Discipline"
                                onClick={() => {
                                    dataView(donneeslivret, 'discipline');
                                }}
                            />
                            <Tab label="Validation" onClick={() => dataView(donneeslivret, 'validation')} />
                        </Tabs>
                        <h1 className="textCenter xs_fontSize20px mt5">{donneeslivret.titre}</h1>
                        <TabPanel value={value} index={0}>
                            {viewModule}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {viewModule}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {viewModule}
                        </TabPanel>
                    </CardComponent>
                </div>
            );
        default:
            return <></>;
    }
};

function mapDispatchToProps(dispatch) {
    return {
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
        getdata(data) {
            dispatch({ type: 'getData', data });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewEleve);
