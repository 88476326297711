/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 *
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Tabs,
    Tab,
    IconButton,
    Dialog,
    DialogContent,
    DialogContentText,
    Button,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import token from '../../reducer/token';

import Container from '../../components/atoms/Container/container';
import { POST, GET, DELETE } from '../../components/Utils/requete';
import PaginationTable from '../../components/organism/Table/Pagination';
import TableRecherche from '../../components/organism/Table/TableRecherche';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import SelectMultiple from '../../components/organism/SelectMultiple/SelectMultiple';
import TabPanel from '../../components/organism/Onglet/TabPanel';
import ListeButton from '../../components/atoms/ButtonCustom';
import Reduire from '../../components/organism/Reduire/Reduire';
import creationTableauFiltre from '../../components/Utils/createTableauFiltre';
import Contact from '../../components/organism/Contact/Contact';
import { suppressionDoublon } from '../../components/Utils/suppressionDoublon';
import TempCoursFormat from '../../components/organism/MiseEnFormeTempsCours/TempCoursFormat';
import ExportExcel from '../../components/organism/ExportTableau/exportExcel';
import CustomTooltips from '../../components/organism/Tooltip/customTooltip';
import setSelect from '../../components/Utils/setSelect';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import {
    tableHeadAtelier,
    tableHeadEleve,
    individuelFiltre,
    badge,
    tableHeadAtelierExport,
    tableHeadEleveExport,
    requeteFiltre,
    selectMultipleListeAtelierPart1,
    selectMultipleListeAtelierPart2,
    tableauSaveFiltre,
} from './consts';
import * as fonctionAtelier from '../../fonctionsRoles/atelier';

const ListeAtelier = (props) => {
    const historyLoc = useLocation();
    const infoUser = token.getState();
    const usurpation = infoUser.userC;
    const css = useStylesMaterielUIGlobal();
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const refreshMultiple = useSelector((state) => state.SelectMultiple.refreshMultiple);
    const filtreMultiple = useSelector((state) => state.SelectMultiple);
    const filtreSimple = useSelector((state) => state.SelectSimple);
    const saveFiltre = useSelector((state) => state.SaveFiltre.listeAteliers);
    const [value, setValue] = useState(0);
    const [dataAtelier, setDataAtelier] = useState([]);
    const [dataEleve, setDataEleve] = useState([]);
    const [open, setOpen] = useState({ open: false });
    const [tableauFiltre, setTableauFiltre] = useState({
        AnneeScolaire: [],
        Site: [],
        Cycles: [],
        GroupeAteliers: [],
        Annees: [],
        Groupes: [],
        Visibilite: [],
        SousGroupeAteliers: [],
        Salles: [],
        Enseignants: [],
    });
    const [pageinationtableau, setPaginationTableau] = useState([]);
    const [onglet, setOnglet] = useState('atelier');
    const [recherche, setRecherche] = useState(['libelleType', 'site', 'lidEnseignant']);
    const [tableauRecherche, setTableauRecherche] = useState([]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function updatefiltre(keys, valueI) {
        setTableauFiltre((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }

    function Badge(props) {
        let br = '';
        if (window.matchMedia('(max-width: 576px)').matches) {
            br = <br />;
        }
        if (props.lib) {
            return (
                <>
                    <span className={badge[props.type].style}>
                        {badge[props.type].label} {props.lib}
                    </span>
                    {br}
                </>
            );
        } else {
            return <></>;
        }
    }

    function Enseignant(props) {
        const enseignant = props.enseignant.split('|');
        return testDroit(infoUser[usurpation].roleFonction, fonctionAtelier.Lien_profil_eleve_Liste_Atelier) ? (
            <>
                <Link to={`/fiche-agent/${enseignant[0]}`}>{enseignant[1]}</Link>
                <br />
            </>
        ) : (
            enseignant[1]
        );
    }

    function Enseignants(props) {
        if (props.enseignants) {
            return props.enseignants.split('|-|').map((enseignant) => <Enseignant enseignant={enseignant} />);
        } else return <></>;
    }
    async function recupData(request) {
        const data = {
            fk_anneeScolaire: filtreSimple.AnneeScolaire === '' ? null : filtreSimple.AnneeScolaire,
            atelierIndividuel: filtreSimple.AtelierIndividuel === 2 || filtreSimple.AtelierIndividuel === '' ? null : filtreSimple.AtelierIndividuel,
            fk_site: filtreMultiple.Site,
            fk_salle: filtreMultiple.Salles,
            fk_enseignant: filtreMultiple.Enseignants,
            fk_cycle: filtreMultiple.Cycles,
            fk_groupe: filtreMultiple.Groupes,
            fk_annee: filtreMultiple.Annees,
            fk_groupeAtelier: filtreMultiple.GroupeAteliers,
            fk_typeAtelier: filtreMultiple.SousGroupeAteliers,
            fk_visibilite: filtreSimple.Visibilite === '' ? null : filtreSimple.Visibilite,
        };
        if (request === 'atelier') {
            const response = await POST('api/ateliers/ateliers', data);
            setDataAtelier(response.data);
            props.resetPagination();
            setPaginationTableau(response.data);
            setTableauRecherche(response.data);
            setOnglet('atelier');
            setRecherche(['libelleType', 'libelleSite', 'nomPrenomUtilisateur']);
        } else if (request === 'eleve') {
            const response = await POST('api/ateliers/ateliersEleves', data);
            setDataEleve(response.data);
            props.resetPagination();
            setPaginationTableau(response.data);
            setTableauRecherche(response.data);
            setOnglet('eleve');
            setRecherche(['libelleType', 'libelleSite', 'nomPrenomEnseignant', 'nomPrenomEleve', 'libelleInstrument']);
        }
    }

    async function supprimerAtelier() {
        await DELETE(`api/ateliers/${open.idAtelier}`);
        setOpen({ open: false });
        recupData(onglet);
    }
    async function recupFiltre() {
        if (tableauFiltre.AnneeScolaire.length === 0) {
            for (let elem = 0; elem < 7; elem++) {
                const requete = await GET(requeteFiltre[elem].url);
                updatefiltre(Object.keys(tableauFiltre)[elem], requete.data);
            }
        }
        const dataSGA = {
            fk_groupeAtelier: filtreMultiple.GroupeAteliers,
        };
        const sousGroupeAtelierFiltre = await POST('api/ateliers/ateliersTypes', dataSGA);
        updatefiltre('SousGroupeAteliers', creationTableauFiltre(sousGroupeAtelierFiltre.data, 'idTypeAtelier', 'libelleType', 'libelleGroupe'));
        const dataSalle = {
            fk_site: filtreMultiple.Site,
        };
        const salleFiltre = await POST('api/salles/liste', dataSalle);
        updatefiltre('Salles', creationTableauFiltre(salleFiltre.data, 'idSalle', 'libelleSalle', 'libelleSite'));
        const dataEnseignant = {
            fk_anneeScolaire: filtreSimple.AnneeScolaire,
            fk_site: filtreMultiple.Site,
            fk_instrument: [],
        };
        const enseignantFiltre = await POST('api/utilisateursAdministratifs/enseignants', dataEnseignant);
        updatefiltre('Enseignants', enseignantFiltre.data);
    }

    useEffect(() => {
        props.addNomPage('Liste des ateliers');
        setSelect({
            saveFiltre: saveFiltre,
            tableauSaveFiltre: tableauSaveFiltre,
            user: infoUser,
            selectMultiple: filtreMultiple,
            selectSimple: filtreSimple,
            filtre: props.filtre,
            filtreMultiple: props.filtreMultiple,
        });
        if (testDroit(infoUser[usurpation].roleFonction, fonctionAtelier.Trie_site)) {
            const siteAtenne = [];
            for (let index = 0; index < infoUser[usurpation].antennes.length; index++) {
                siteAtenne.push(infoUser[usurpation].antennes[index].idSite);
            }
            props.filtreMultiple([{ Site: siteAtenne }]);
        }
    }, []);

    useEffect(() => {
        recupFiltre();
        recupData(onglet);
        props.removeRecherche();
    }, [refreshSimple, refreshMultiple]);

    return (
        <div className="listeAtelier">
            <Dialog
                open={open.open}
                onClose={() => {
                    setOpen({ open: false });
                }}
                maxWidth="lg"
            >
                <DialogContent>
                    <DialogContentText>
                        <p className="cRed">
                            Attention:
                            <br /> - Les enseignants et les élèves seront désassocier de cet atelier.
                            <br /> - Les listes de présences de cet ateliers seront supprimé.
                        </p>
                        <span className="mr2 ">Voulez-vous supprimer cet atelier ?</span>
                        <div className="mt5 textCenter">
                            <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                className={css.mr20}
                                onClick={() => {
                                    supprimerAtelier();
                                }}
                            >
                                OUI
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                color="secondary"
                                onClick={() => {
                                    setOpen({ open: false });
                                }}
                            >
                                Non
                            </Button>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                style={{ position: 'fixed', width: 'calc(100% - 210px)', zIndex: '2', top: '64px' }}
            >
                <Tab
                    label="Cours & Ateliers"
                    onClick={() => {
                        recupData('atelier');
                        props.removeRecherche();
                    }}
                />
                <Tab
                    label="Élève"
                    onClick={() => {
                        recupData('eleve');
                        props.removeRecherche();
                    }}
                />
            </Tabs>
            <div className="m2 ">
                <Container>
                    <Reduire>
                        <div className="m2 xs_flex xs_directionColumn">
                            <SelectSimple
                                tableau={tableauFiltre.AnneeScolaire}
                                indice={'AnneeScolaire'}
                                inputLabel={'Année Scolaire'}
                                classe={[css.wMin25, css.wMax25, css.ml2, css.wMax100]}
                                liste={'atelier'}
                            ></SelectSimple>
                            <SelectSimple
                                tableau={tableauFiltre.Visibilite}
                                indice={'Visibilite'}
                                inputLabel={'Visibilité'}
                                classe={[css.wMin25, css.wMax25, css.ml2, css.wMax100]}
                                liste={'atelier'}
                            ></SelectSimple>
                            {selectMultipleListeAtelierPart1.map((row) => {
                                return (
                                    <SelectMultiple
                                        tableau={tableauFiltre[row.indice]}
                                        inputLabel={row.label}
                                        indice={row.indice}
                                        liste={'atelier'}
                                        classe={[css.wMin25, css.wMax25, css.ml2, css.wMax100]}
                                        disabled={testDroit(infoUser[usurpation].roleFonction, fonctionAtelier.Trie_site) && row.indice === 'Site'}
                                    ></SelectMultiple>
                                );
                            })}
                            <SelectSimple
                                tableau={individuelFiltre}
                                indice={'AtelierIndividuel'}
                                inputLabel={'Type atelier'}
                                classe={[css.wMin25, css.wMax25, css.ml2, css.wMax100]}
                                liste={'atelier'}
                            ></SelectSimple>
                            {selectMultipleListeAtelierPart2.map((row) => {
                                return (
                                    <SelectMultiple
                                        tableau={tableauFiltre[row.indice]}
                                        inputLabel={row.label}
                                        indice={row.indice}
                                        liste={'atelier'}
                                        classe={[css.wMin25, css.wMax25, css.ml2, css.wMax100]}
                                        valeurNull={row.valeurNull ? row.valeurNull : false}
                                    ></SelectMultiple>
                                );
                            })}
                        </div>
                    </Reduire>
                    <div className="flex alignICenter xs_directionColumn">
                        <TableRecherche tableau={tableauRecherche} className={[css.w100, css.flex, css.ml2, css.mr2]} indice={recherche}></TableRecherche>
                        <div className="flex w100 flexEnd xs_mt5 xs_mb5 xs_flexCenter">
                            <ListeButton
                                variant="outlined"
                                color="secondary"
                                size="small"
                                onClick={() => {
                                    props.removeFiltre();
                                    props.removeFiltreMultiple();
                                    props.removeSaveFiltre();
                                    props.filtre([
                                        {
                                            AnneeScolaire: infoUser.idAnneeScolaire,
                                        },
                                        { AtelierIndividuel: 2 },
                                    ]);
                                }}
                            >
                                Supprimer les filtres
                            </ListeButton>
                            {testDroit(infoUser[usurpation].roleFonction, fonctionAtelier.Ajout_nouvel_atelier) ? (
                                <Link
                                    to="/fiche-atelier/0"
                                    onClick={() => {
                                        props.addNomPage('Fiche atelier - nouvel atelier');
                                    }}
                                >
                                    <ListeButton variant="outlined" size="small" color="primary">
                                        Ajouter un atelier
                                    </ListeButton>
                                </Link>
                            ) : null}
                        </div>
                        <div className="flex w100 flexEnd">
                            <ExportExcel
                                data={onglet === 'atelier' ? dataAtelier : dataEleve}
                                head={onglet === 'atelier' ? tableHeadAtelierExport : tableHeadEleveExport}
                                nomFichier="Liste Atelier"
                            />
                        </div>
                        <div className="w100">
                            <PaginationTable
                                data={props.recherche.fn(pageinationtableau)}
                                page={props.Page[0].page}
                                row={props.Page[0].rowsPerPage}
                            ></PaginationTable>
                        </div>
                    </div>
                    <TabPanel value={value} index={0}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeadCustom data={tableHeadAtelier}></TableHeadCustom>
                                <TableBody>
                                    {setData(
                                        props.recherche.fn,
                                        dataAtelier,
                                        props.Page[0].page,
                                        props.Page[0].rowsPerPage,
                                        props.orderState,
                                        props.orderByState,
                                    ).map((row, index) => {
                                        if (onglet === 'atelier') {
                                            return (
                                                <TableRow className={css.trbody} key={index}>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        <div>Type</div>
                                                        {testDroit(infoUser[usurpation].roleFonction, fonctionAtelier.Supprimer_atelier) ? (
                                                            <CustomTooltips title={'supprimer cet atelier'}>
                                                                <IconButton
                                                                    color="secondary"
                                                                    className={[css.p0, css.floatRight]}
                                                                    onClick={() => setOpen({ open: true, idAtelier: row.idAtelier })}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </CustomTooltips>
                                                        ) : null}
                                                        <CustomTooltips title="Éditer l'atelier">
                                                            <Link
                                                                to={`/fiche-atelier/${row.idAtelier}`}
                                                                onClick={() => {
                                                                    props.addNomPage(`Atelier - ${row.libelleType}`);
                                                                    props.removeRecherche();
                                                                    props.savePathAtelier(historyLoc.pathname);
                                                                }}
                                                            >
                                                                {row.libelleType ? row.libelleType : 'non renseigné'}
                                                            </Link>
                                                        </CustomTooltips>
                                                        {window.matchMedia('(max-width: 576px)').matches ? <br></br> : null}
                                                        <Badge lib={row.libelleCycle} type={1} />
                                                        <Badge lib={row.libelleAnnee} type={2} />
                                                        <Badge lib={row.libelleGroupeSI} type={5} />
                                                        <Badge lib={row.libelleGroupeAtelier} type={3} />
                                                        <Badge lib={row.countEleves} type={4} />
                                                    </TableCell>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        <div>Site/Salle</div>
                                                        {`${row.libelleSite}${row.libelleSalle === null ? '' : ` / ${row.libelleSalle}`}`}
                                                    </TableCell>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        {!row.jour ? null : <div>Horaire</div>}
                                                        <TempCoursFormat heureDebut={row.heureDebut} heureFin={row.heureFin} jour={row.jour} />
                                                    </TableCell>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        <div>Enseignant</div>
                                                        <Enseignants enseignants={row.enseignants} />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        } else {
                                            return <></>;
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeadCustom data={tableHeadEleve}></TableHeadCustom>
                                <TableBody>
                                    {setData(
                                        props.recherche.fn,
                                        dataEleve,
                                        props.Page[0].page,
                                        props.Page[0].rowsPerPage,
                                        props.orderState,
                                        props.orderByState,
                                    ).map((row, index) => {
                                        if (onglet === 'eleve') {
                                            return (
                                                <TableRow className={css.trbody} key={index}>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        <div>Type</div>
                                                        {testDroit(infoUser[usurpation].roleFonction, fonctionAtelier.Supprimer_atelier) ? (
                                                            <CustomTooltips title={'supprimer cette atelier'}>
                                                                <IconButton
                                                                    color="secondary"
                                                                    className={[css.p0, css.floatRight]}
                                                                    onClick={() => setOpen({ open: true, idAtelier: row.fk_atelier })}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </CustomTooltips>
                                                        ) : null}
                                                        {testDroit(infoUser[usurpation].roleFonction, fonctionAtelier.Lien_atelier) ? (
                                                            <CustomTooltips title="Éditer l'atelier">
                                                                <Link
                                                                    to={`/fiche-atelier/${row.fk_atelier}`}
                                                                    onClick={() => {
                                                                        props.addNomPage(`Atelier - ${row.type}`);
                                                                        props.savePathAtelier(historyLoc.pathname);
                                                                    }}
                                                                >
                                                                    {row.libelleType ? row.libelleType : 'non renseigné'}
                                                                </Link>
                                                            </CustomTooltips>
                                                        ) : row.libelleType ? (
                                                            row.libelleType
                                                        ) : (
                                                            'non renseigné'
                                                        )}
                                                        <br />
                                                        <Badge lib={row.libelleCycle} type={1} />
                                                        <Badge lib={row.libelleAnnee} type={2} />
                                                        <Badge lib={row.libelleGroupe} type={3} />
                                                    </TableCell>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        <div>Site</div>
                                                        {row.libelleSite}
                                                    </TableCell>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        <div>Enseignant</div>
                                                        {testDroit(infoUser[usurpation].roleFonction, fonctionAtelier.Lien_profil_enseignant_Liste_Atelier) ? (
                                                            <Link to={`/fiche-agent/${row.fk_enseignant}`}>{row.nomPrenomEnseignant}</Link>
                                                        ) : (
                                                            row.nomPrenomEnseignant
                                                        )}
                                                    </TableCell>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        <div>Elève</div>
                                                        {testDroit(infoUser[usurpation].roleFonction, fonctionAtelier.Lien_profil_eleve_Liste_Atelier) ? (
                                                            <Link to={`/fiche-eleve/${row.idEleve}`}>{row.nomPrenomEleve}</Link>
                                                        ) : (
                                                            row.nomPrenomEleve
                                                        )}
                                                    </TableCell>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        {!row.libelleInstrument ? null : <div>Instrument</div>}
                                                        {row.libelleInstrument}
                                                    </TableCell>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        {!row.telephoneEleve ? null : <div>Télèphone</div>}
                                                        {suppressionDoublon(row.telephoneEleve).map((e) => {
                                                            return <Contact telephone={e.toString()} />;
                                                        })}
                                                    </TableCell>
                                                    <TableCell className={css.td} component="th" scope="row">
                                                        <div>Email</div>
                                                        {suppressionDoublon(row.emails).map((e) => {
                                                            return (
                                                                <>
                                                                    <Contact email={e.toString()} />
                                                                    <br />
                                                                </>
                                                            );
                                                        })}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        } else {
                                            return <></>;
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <PaginationTable data={props.recherche.fn(pageinationtableau)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                </Container>
            </div>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        removeFiltre() {
            dispatch({ type: 'removeFiltre' });
        },
        removeFiltreMultiple() {
            dispatch({ type: 'removeFiltreMultiple' });
        },
        removeSaveFiltre() {
            dispatch({ type: 'removeSave', liste: 'atelier' });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        filtreMultiple(filtreMultiple) {
            dispatch({ type: 'multiple', filtreMultiple });
        },
        removeRecherche() {
            dispatch({ type: 'removeRecherche' });
        },
        resetPagination() {
            dispatch({ type: 'resetPagination' });
        },
        savePathAtelier(pathAtelier) {
            dispatch({ type: 'savePathAtelier', pathAtelier });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ListeAtelier);
