export function testDroit(array, idFonction) {
    // console.log('test fonction ', idFonction)
    const longueur = array.length;
    // console.log('longueur', longueur)

    // on trouve l'octet ou est stocké le droit
    const octet = longueur - Math.floor((idFonction - 1) / 8) - 1;
    // console.log('octet', octet);

    const droits = array[octet];
    // console.log('droits', droits);

    // on trouve le bit correspondant dans l'octet
    const shift = idFonction % 8;
    // console.log('idFonction', idFonction, shift, (1 << shift));

    // on teste si sa valeur est à 1
    return (droits & (1 << +shift)) === 1 << +shift;
}
