export const Lien_profil = 156;
export const Lien_cours = 157;
export const Modification_services = 158;
export const Ajout_affectation = 159;
export const Suppression_affectation = 160;
export const Ajout_décharge = 161;
export const Suppression_décharge = 162;
export const Emploi = 163;
export const Contrat = 164;
export const Décharge = 165;
export const Modification_commentaire = 243;

export const Moncompte_Modification_services = 253;
export const Moncompte_Ajout_affectation = 254;
export const Moncompte_Suppression_affectation = 255;
export const Moncompte_Ajout_décharge = 256;
export const Moncompte_Suppression_décharge = 257;
export const Moncompte_Emploi = 258;
export const Moncompte_Contrat = 259;
export const Moncompte_Décharge = 260;
export const Moncompte_Modification_commentaire = 268;
