/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FormControl, Select } from '@material-ui/core';

import { GET, POST } from '../../Utils/requete';
import Input from '../../atoms/TextField';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const TrajetFrais = (props) => {
    let jour = `jour ${props.jour}`;
    let trajet = `trajet ${props.trajet - 1}`;
    const { type } = useParams();
    const { id } = useParams();
    const trajetV = `trajet ${props.trajet}`;
    const initTrajet = 'trajet 0';
    const css = useStylesMaterielUIGlobal();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const infoJour = useSelector((state) => state.FraisJour);
    const refreshJour = useSelector((state) => state.FraisJour.refreshJour);
    const newJour = useSelector((state) => state.FraisJour[jour].newJour);
    const refreshTrajet = useSelector((state) => state.FraisJour[jour].refreshTrajet);
    const destination = infoJour[jour][trajetV] === undefined ? '' : infoJour[jour][trajetV].trajetV;
    const depart = infoJour[jour][trajetV] === undefined ? '' : infoJour[jour][trajetV].trajetD;
    const kilometrage = infoJour[jour][trajetV] === undefined ? 0 : infoJour[jour][trajetV].km;
    const [disabledD, setDisabledD] = useState(false);
    const [disabledV, setDisabledV] = useState(false);
    const [tableauCommune, setTableauCommune] = useState([]);
    const [dernierTrajet, setDernierTrajet] = useState(true);
    // eslint-disable-next-line eqeqeq
    const [KM, setKM] = useState(id == 0 ? undefined : 0);
    function envoiData(M, valueI, data) {
        const ville = `trajet${M}`;
        props.fraisTrajet({ [ville]: valueI }, props.jour, props.trajet);
        if (M === 'D') {
            if (props.trajet - 1 >= 0) {
                props.fraisTrajet({ trajetV: valueI }, props.jour, props.trajet - 1);
                props.fraisJour({ newJour: !infoJour[jour].newJour }, props.jour);
            }
        }
        if (M === 'V') {
            if (props.trajet !== infoJour[jour].DernierTrajet && !data) {
                props.fraisTrajet({ trajetD: valueI }, props.jour, props.trajet + 1);
            }

            props.fraisJour({ newJour: !infoJour[jour].newJour }, props.jour);
        }
    }
    async function kilometre() {
        trajet = `trajet ${props.trajet}`;
        if (!KM) {
            if (
                infoJour[jour][trajet].trajetD !== '' &&
                infoJour[jour][trajet].trajetV !== '' &&
                infoJour[jour][trajet].trajetD !== undefined &&
                infoJour[jour][trajet].trajetV !== undefined
            ) {
                const data = {
                    idCommuneA: infoJour[jour][trajet].trajetD,
                    idCommuneB: infoJour[jour][trajet].trajetV,
                };
                const response = await POST('/api/frais/getCommunesDistance', data, {
                    redirectOnError: false,
                });
                if (response.success) {
                    if (KM !== 0 || !props.dataTrajets) {
                        props.fraisTrajet({ km: response.data.kilometrage }, props.jour, props.trajet);
                    }
                } else {
                    props.notification({
                        message: "Cette commune est n'est pas referencé pour les notes de frais",
                    });
                    props.fraisTrajet({ km: 0 }, props.jour, props.trajet);
                }
            }
        } else {
            props.fraisTrajet({ km: KM }, props.jour, props.trajet);
            setKM();
        }
    }
    function recupDatatrajet() {
        if (props.dataTrajets) {
            envoiData('D', props.dataTrajets.fk_siteA);
            envoiData('V', props.dataTrajets.fk_siteB, 'data');
            setKM(props.dataTrajets.kilometrage);
        }
    }
    async function recupfiltre() {
        const response = await GET('/api/frais/fraisCommunes');
        setTableauCommune(response.data);
    }
    useEffect(() => {
        if (type) {
            setDisabledD(true);
            setDisabledV(true);
        }
        // eslint-disable-next-line eqeqeq
        if (props.trajet == 0) {
            props.fraisTrajet({ trajetD: user[usurpation].idCommuneDepart }, props.jour, props.trajet);
            setDisabledD(true);
        } else {
            props.fraisTrajet({ trajetD: infoJour[jour][trajet].trajetV }, props.jour, props.trajet);
        }
    }, [dernierTrajet]);
    useEffect(() => {
        if (infoJour[jour][trajet] !== undefined) {
            if (infoJour[jour][trajet].trajetD) {
                trajet = `trajet ${props.trajet}`;
                const trajetD0 = 'trajet 0';
                if (
                    // eslint-disable-next-line eqeqeq
                    props.trajet == infoJour[jour].DernierTrajet - 2 &&
                    infoJour[jour][trajet].trajetV === infoJour[jour][trajetD0].trajetD
                ) {
                    props.fraisTrajet({ trajetV: '' }, props.jour, props.trajet);
                    props.fraisTrajet({ km: 0 }, props.jour, props.trajet);
                }
            }
        }
        // eslint-disable-next-line eqeqeq
        if (props.trajet + 1 == infoJour[jour].DernierTrajet && props.trajet != 0) {
            trajet = `trajet ${infoJour[jour].DernierTrajet - 2}`;
            setDisabledV(true);
            setDisabledD(true);
            props.fraisTrajet({ trajetV: infoJour[jour][initTrajet].trajetD }, props.jour, props.trajet);
            props.fraisTrajet({ trajetD: infoJour[jour][trajet].trajetV }, props.jour, props.trajet);
            // eslint-disable-next-line eqeqeq
        } else if (props.trajetD != infoJour[jour].DernierTrajet && props.trajet != 0) {
            setDisabledV(false);
            setDisabledD(false);
        }
    }, [refreshJour, refreshTrajet]);
    useEffect(() => {
        recupfiltre();
        recupDatatrajet();
    }, []);
    useEffect(() => {
        kilometre();
    }, [newJour]);
    return (
        <div className="nomargin">
            <div className="flex xs_directionColumn">
                <div className="textRight flex alignICenter mb2 w30 xs_w100">
                    <label className="w25">depuis</label>
                    <FormControl className={[css.nomargin, css.ml30px, css.w50]}>
                        <Select
                            disabled={disabledD}
                            native
                            value={depart}
                            onChange={(event) => {
                                envoiData('D', event.target.value);
                                setDernierTrajet(!dernierTrajet);
                            }}
                        >
                            <option value={''}></option>
                            {tableauCommune.map((elem, index) => {
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <option key={index} value={elem.idCommune}>
                                        {elem.libelleCommune}
                                    </option>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="textRight flex alignICenter mb2 w30 xs_w100">
                    <label className="w25">vers</label>
                    <FormControl className={[css.nomargin, css.ml30px, css.w50]}>
                        <Select
                            disabled={disabledV}
                            native
                            value={destination}
                            onChange={(event) => {
                                envoiData('V', event.target.value);
                                setDernierTrajet(!dernierTrajet);
                            }}
                        >
                            <option value={''}></option>
                            {tableauCommune.map((elem, index) => {
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <option key={index} value={elem.idCommune}>
                                        {elem.libelleCommune}
                                    </option>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="textRight flex alignICenter mb2 w30 xs_w100">
                    <label className="w25">Kilométrage</label>
                    <Input
                        InputProps={{ inputProps: { min: 0 } }}
                        disabled={true}
                        value={kilometrage}
                        className={[css.nomargin, css.ml30px, css.w50]}
                        onChange={(event) => {}}
                    />
                </div>
            </div>
            <hr className="none xs_block"></hr>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        fraisTrajet(ville, nbJ, trajet) {
            dispatch({ type: 'addTrajet', ville, nbJ, trajet });
        },
        fraisJour(data, nb) {
            dispatch({ type: 'jour', data, nb });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
export default connect(null, mapDispatchToProps)(TrajetFrais);
