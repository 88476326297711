import React, { useState, useEffect } from 'react';

import { connect, useSelector } from 'react-redux';

import { GET, POST } from '../../Utils/requete';
import ListeButton from '../../atoms/ButtonCustom';
import { testDroit } from '../../Utils/testDroit';

const CalculCotisation = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [infoAnnee, setInfoAnnee] = useState({ libelleAnneeScolaire: '', valeur: '' });
    let callback = undefined;
    const params = {
        fk_anneeScolaire: null,
    };
    async function infoAnneeInscription() {
        const response = await GET('/api/parametres/list?categorie=inscription', undefined, { redirectOnError: false });
        const filtreAnneeScolaire = await GET('/api/anneesScolaire/list');
        if (response) {
            let index = response.data.findIndex((e) => e.cle === 'anneeInscriptions');
            response.data[index].libelleAnneeScolaire = filtreAnneeScolaire.data.filter(
                (e) => e.idAnneeScolaire === +response.data[index].valeur,
            )[0].libelleAnneeScolaire;
            setInfoAnnee(response.data[index]);
        }
    }
    async function handleClickCotisation(event) {
        event.preventDefault();

        const result = await POST('/api/cotisations/calcul', params, { redirectOnError: false });
        if (result.success) {
            props.notification({
                message: 'Le calcul des cotisations a été effectué',
                status: result.success,
            });
            if (callback) {
                callback();
            }
        } else {
            props.notification({
                message: result.message,
            });
        }
        return;
    }

    useEffect(() => {
        infoAnneeInscription();
    }, []);
    if (testDroit(user[usurpation].roleFonction, props.idFonction)) {
        params.fk_anneeScolaire = infoAnnee.valeur;
        delete params.fk_responsable;
        callback = props.callback;
        if (props.responsable) {
            params.fk_responsable = props.responsable;
        }
        return (
            <div className={props.className} style={props.style}>
                <ListeButton variant="outlined" size="small" color="primary" onClick={handleClickCotisation}>
                    Calculer les cotisations {infoAnnee.libelleAnneeScolaire}
                </ListeButton>
            </div>
        );
    } else {
        return <></>;
    }
};

function mapDispatchToProps(dispatch) {
    return {
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(CalculCotisation);
