/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { IconButton, TextareaAutosize, FormControl, Select } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import Input from '../../atoms/TextField';
import TrajetFrais from './trajetFrais';
import DateInput from '../Input/date';
import HeureInput from '../Input/heure';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const JourFrais = (props) => {
    const css = useStylesMaterielUIGlobal();
    const jour = `jour ${props.jour}`;
    const { type } = useParams();
    const gestion = type === 'gestion' ? true : false;
    const [dataInput, setDataInput] = useState({
        date: '',
        heureDepart: '',
        MDivers: 0,
        NBR: 0,
        heureRetour: '',
        motif: '',
        typeJour: '',
    });
    const [trajet, settrajet] = useState([]);
    const [delT, setDelT] = useState(false);
    const infoJour = useSelector((state) => state.FraisJour);
    const refreshJour = useSelector((state) => state.FraisJour.refreshJour);
    function update(FT, keys, valueI) {
        props.fraisJour({ [keys]: valueI }, props.jour);
        if (FT === 'input') {
            setDataInput((prevState) => ({
                ...prevState,
                [keys]: valueI,
            }));
        }
    }
    function createTrajet(dataJ) {
        let elemDiv = [];
        if (dataJ) {
            for (let elem = 0; elem < dataJ.fraisTrajets.length; elem++) {
                const data = (
                    <div className="ml2 mr2">
                        <TrajetFrais jour={props.jour} trajet={elem} dataTrajets={dataJ.fraisTrajets[elem]} />
                    </div>
                );
                elemDiv.push(data);
                props.fraisJour(
                    {
                        DernierTrajet: elem + 1,
                    },
                    props.jour,
                );
            }
            settrajet(...trajet, elemDiv);
        } else {
            if (trajet.length === 0) {
                for (let index = 0; index < 2; index++) {
                    const data = (
                        <div className="ml2 mr2">
                            <TrajetFrais jour={props.jour} trajet={index} />
                        </div>
                    );
                    elemDiv.push(data);
                    props.fraisJour(
                        {
                            DernierTrajet: index + 1,
                        },
                        props.jour,
                    );
                    settrajet(...trajet, elemDiv);
                }
            } else {
                elemDiv = (
                    <div className="ml2 mr2">
                        <TrajetFrais jour={props.jour} trajet={trajet.length} />
                    </div>
                );
                props.fraisJour({ DernierTrajet: trajet.length + 1 }, props.jour);
                settrajet([...trajet, elemDiv]);
            }
        }
    }
    function delTrajet(index) {
        props.removeTrajet(props.jour, trajet.length - 1);
        props.fraisJour({ DernierTrajet: trajet.length - 1 }, props.jour);
        props.fraisJour({ refreshTrajet: !infoJour[jour].refreshTrajet }, props.jour);
        const tableau = trajet;
        tableau.splice(index - 1, 1);
        settrajet(tableau);
        setDelT(!delT);
    }
    useEffect(() => {
        update('input', 'typeJour', 1);
        update('input', 'id', props.jour);
        props.fraisJour({ typeJour: 1 }, props.jour);
        if (trajet.length === 0) {
            for (let index = 0; index < 2; index++) {
                createTrajet(props.dataJ);
            }
        }
        if (props.dataJ) {
            props.fraisJour({ date: props.dataJ.dateJour }, props.jour);
            props.fraisJour({ idFraisJournee: props.dataJ.idFraisJournee }, props.jour);
            update('input', 'typeJour', props.dataJ.fk_fraisType);
            update('input', 'date', new Date(props.dataJ.dateJour));
            update('input', 'heureDepart', new Date(`${props.dataJ.dateJour} ${props.dataJ.heureDepart}`));
            update('input', 'MDivers', props.dataJ.montantDivers);
            update('input', 'NBR', props.dataJ.nombreRepas);
            update('input', 'heureRetour', new Date(`${props.dataJ.dateJour} ${props.dataJ.heureRetour}`));
            update('input', 'motif', props.dataJ.motif);
        }
    }, []);
    useEffect(() => {
        props.fraisJour({ newJour: true }, props.jour);
    }, []);
    useEffect(() => {}, [refreshJour, delT]);
    return (
        <div className="nomargin" id={`${props.jour}_jour`}>
            <div className="flex xs_directionColumn">
                <div className="w50 xs_w100">
                    <DateInput
                        label={'Date'}
                        disabled={gestion}
                        value={dataInput.date}
                        format="dd/MM/yyyy"
                        className={[css.w50, css.nomargin, css.ml30px]}
                        minDate={new Date(infoJour.periode.getFullYear(), infoJour.periode.getMonth(), 1)}
                        maxDate={new Date(infoJour.periode.getFullYear(), infoJour.periode.getMonth() + 1, 0)}
                        onChange={(event) => {
                            update('input', 'date', new Date(event));
                        }}
                    />
                    <div className="textRight alignICenter flex mb2">
                        <label className="w25">Type</label>
                        <FormControl className={[css.w50, css.nomargin, css.ml30px]}>
                            <Select
                                disabled={gestion}
                                native
                                value={dataInput.typeJour}
                                onChange={(event) => {
                                    update('input', 'typeJour', event.target.value);
                                }}
                            >
                                <option value={1}>Régulier</option>
                                <option value={2}>Mission</option>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="textRight alignICenter flex mb2">
                        <label className="w25">Kilométrage</label>
                        <Input
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            disabled={true}
                            value={infoJour[jour] === undefined ? 0 : infoJour[jour].kilometrage}
                            className={[css.w50, css.nomargin, css.ml30px]}
                        />
                    </div>
                </div>
                <div className="flex directionColumn">
                    <div className="flex  xs_directionColumn xs_mb2">
                        <div className="w50 xs_w100">
                            <HeureInput
                                label={'Heure de départ'}
                                disabled={gestion}
                                value={dataInput.heureDepart}
                                className={[css.w50, css.nomargin, css.ml30px]}
                                onChange={(event) => {
                                    update('input', 'heureDepart', new Date(event));
                                }}
                            />
                            <div className="textRight alignICenter flex mb2">
                                <label className="w25">Nombre de repas</label>
                                <Input
                                    disabled={gestion}
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dataInput.NBR}
                                    className={[css.w50, css.nomargin, css.ml30px]}
                                    onChange={(event) => {
                                        update('input', 'NBR', event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w50 xs_w100">
                            <HeureInput
                                label={'Heure de retour'}
                                disabled={gestion}
                                value={dataInput.heureRetour}
                                className={[css.w50, css.nomargin, css.ml30px]}
                                onChange={(event) => {
                                    update('input', 'heureRetour', new Date(event));
                                }}
                            />
                            <div className="textRight alignICenter flex mb2">
                                <label className="w25">Montant divers</label>
                                <Input
                                    disabled={gestion}
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dataInput.MDivers}
                                    className={[css.w50, css.nomargin, css.ml30px]}
                                    onChange={(event) => {
                                        update('input', 'MDivers', event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="textRight alignICenter flex mb2">
                        <TextareaAutosize
                            disabled={gestion}
                            className={css.textareaML30pxW80MB1emMT0}
                            aria-label="minimum height"
                            rowsMin={4}
                            placeholder="Description de la mission"
                            value={dataInput.motif}
                            onChange={(event) => {
                                update('input', 'motif', event.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="w80 ml10">
                <div className="flex">
                    <h3 className="w100 mb0">Trajet</h3>
                    <IconButton disabled={gestion} className={[css.cVert, css.p0]} onClick={() => delTrajet(trajet.length)}>
                        <IndeterminateCheckBoxIcon />
                    </IconButton>
                    <IconButton disabled={gestion} className={[css.cVert, css.p0]} onClick={() => createTrajet()}>
                        <AddBoxIcon />
                    </IconButton>
                </div>
                <hr></hr>
                <div>{trajet}</div>
            </div>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        fraisJour(data, nb) {
            dispatch({ type: 'jour', data, nb });
        },
        removeTrajet(nb, trajet) {
            dispatch({ type: 'removetrajet', nb, trajet });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
    };
}
export default connect(null, mapDispatchToProps)(JourFrais);
