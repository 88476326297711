/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author viaud Benjamin
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, TextareaAutosize } from '@material-ui/core';

import { GET, POST } from '../../components/Utils/requete';
import TriToggle from '../../components/organism/Tri_Toggle/triToggle';
import { testDroit } from '../../components/Utils/testDroit';
import TempCoursFormat from '../../components/organism/MiseEnFormeTempsCours/TempCoursFormat';
import valuesQuery from '../../components/Utils/valuesQuery';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import * as fonctionAtelier from '../../fonctionsRoles/atelier';

const FichePresence = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const css = useStylesMaterielUIGlobal();
    const date = new Date();
    let { id } = useParams();
    const [dataPresences, setDataPresences] = useState({
        idPresence: undefined,
        fk_atelier: undefined,
        date: '',
        commentaire: '',
        dateCreation: '',
        dateValidation: null,
        eleves: [],
    });

    async function recupFichePresenceParAtelier() {
        let dateAtelier = await GET(`/api/ateliers/${id}`);
        dateAtelier = new Date(date.setDate(date.getDate() - date.getDay() + dateAtelier.data.jour)).toLocaleDateString().split('/').reverse().join('-');
        if (valuesQuery('date')) dateAtelier = valuesQuery('date');
        const response = await GET(`/api/presences/parAtelier/${id}?date=${dateAtelier}`, undefined, {
            redirectOnError: false,
        });
        if (response.success) {
            setDataPresences(response.data);
        }
    }
    async function saveFichePresence() {
        if (
            // eslint-disable-next-line eqeqeq
            new Date().toLocaleDateString().split('/').reverse().join() >= new Date(dataPresences.date).toLocaleDateString().split('/').reverse().join()
        ) {
            for (let index = 0; index < dataPresences.eleves.length; index++) {
                if (!dataPresences.eleves[index].fk_statutPresence) {
                    return props.notification({ message: 'La présence de tous les élèves doit être vérifiée' });
                }
            }
            const dateValidation = new Date().toLocaleDateString().split('/').reverse();
            dataPresences.dateValidation = dateValidation.join('-');
            const response = await POST(`/api/presences/${id}`, dataPresences);
            if (response.success) {
                recupFichePresenceParAtelier();
                props.notification({
                    message: 'Fiche de présence enregistrée',
                    status: response.success,
                });
            }
        } else {
            props.notification({ message: "Vous n'avez pas le droit de valider cette fiche de présence" });
        }
    }
    function AbsensePrevu() {
        const affichageAbsences = [];
        dataPresences.eleves.map((row, index) => {
            if (dataPresences.eleves[index].fk_statutPresence === 1) {
                if (window.matchMedia('(max-width: 576px)').matches) {
                    affichageAbsences.push(
                        <div>
                            <span>{row.nomPrenomEleve}</span>
                            <br />
                        </div>,
                    );
                } else {
                    affichageAbsences.push(
                        <TableRow key={index} className={css.trbody}>
                            <TableCell className={css.td} component="th" scope="row">
                                {row.nomPrenomEleve}
                            </TableCell>
                            <TableCell className={css.td} component="th" scope="row"></TableCell>
                        </TableRow>,
                    );
                }
            }
        });
        if (affichageAbsences.length === 0) {
            return <></>;
        }
        if (window.matchMedia('(max-width: 576px)').matches) {
            return (
                <>
                    <h4>Les élèves excusés</h4>
                    {affichageAbsences}
                </>
            );
        } else {
            return (
                <>
                    <h4>Les élèves excusés</h4>
                    <TableContainer component={Paper} className={css.mb5}>
                        <Table>
                            <TableBody className={css.tbody}>{affichageAbsences}</TableBody>
                        </Table>
                    </TableContainer>
                </>
            );
        }
    }
    useEffect(() => {
        props.addNomPage('Fiche présence');
        recupFichePresenceParAtelier();
    }, []);
    function valueTriToggle(childData, index) {
        let statutPresence;
        switch (childData) {
            case 'premierCheck':
                statutPresence = 2;
                break;
            case 'deuxiemeCheck':
                statutPresence = null;
                break;
            case 'troisiemeCheck':
                statutPresence = 4;
                break;
            default:
                break;
        }
        dataPresences.eleves[index].fk_statutPresence = statutPresence;
    }
    function defaultValue(fk_statutPresence) {
        switch (fk_statutPresence) {
            case 2:
                return 'premierCheck';
            case 4:
                return 'troisiemeCheck';
            default:
                return 'deuxiemeCheck';
        }
    }
    if (window.matchMedia('(max-width: 576px)').matches) {
        return (
            <div className="listeContainer">
                <AbsensePrevu />
                <h4>Élèves présents pour le cours {dataPresences.eleves.length !== 0 ? ` "${dataPresences.libelleType}"` : null}</h4>
                {dataPresences.eleves.map((row, index) => {
                    if (dataPresences.eleves[index].fk_statutPresence !== 1) {
                        return (
                            <div>
                                <span>{`${row.nomPrenomEleve}: `}</span>
                                <TriToggle index={(index, index)} label1="Présent" label2="Absent" value={valueTriToggle} className="ml20 mb3 mt2" />
                            </div>
                        );
                    }
                })}
                {!dataPresences.dateValidation && testDroit(user[usurpation].roleFonction, fonctionAtelier.Validation_presences) ? (
                    <Button variant="outlined" size="small" color={'primary'} onClick={() => saveFichePresence()}>
                        Valider la liste
                    </Button>
                ) : (
                    <></>
                )}
            </div>
        );
    }

    return (
        <div className="listeContainer">
            <div className="flex spaceBetween alignICenter">
                <h3 className="mb0">
                    Fiche de présence pour l'atelier -
                    {dataPresences.eleves.length !== 0 ? (
                        <Link
                            to={`/fiche-atelier/${id}`}
                            onClick={() => {
                                props.addNomPage(`Atelier - ${dataPresences.libelleType}`);
                            }}
                        >
                            {dataPresences.libelleType}
                        </Link>
                    ) : null}
                </h3>
                {!dataPresences.dateValidation ? (
                    <Button variant="outlined" size="small" color={'primary'} onClick={() => saveFichePresence()}>
                        Valider la liste
                    </Button>
                ) : (
                    <></>
                )}
            </div>
            <p className="nomargin fontLittleSmallGrey">
                {`${new Date(dataPresences.date).toLocaleString('fr-FR', { weekday: 'long', month: 'long', day: 'numeric' })} `}
                <TempCoursFormat heureDebut={dataPresences.heureDebut} heureFin={dataPresences.heureFin} />
            </p>
            <hr></hr>
            {dataPresences.eleves.length !== 0 ? <h4>Élève(s) présent(s) pour ce cours</h4> : <h4>Il n'y a pas d'élève pour ce cours</h4>}
            <TableContainer component={Paper}>
                <Table>
                    <TableBody className={css.tbody}>
                        {dataPresences.eleves.map((row, index) => {
                            if (dataPresences.eleves[index].fk_statutPresence !== 1) {
                                return (
                                    <TableRow key={index} className={css.trbody}>
                                        <TableCell className={css.td} component="th" scope="row">
                                            {row.nomPrenomEleve}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <TriToggle
                                                index={(index, index)}
                                                label1="Présent"
                                                label2="Absent"
                                                value={valueTriToggle}
                                                defaultValue={defaultValue(row.fk_statutPresence)}
                                            />
                                        </TableCell>
                                        <TableCell className={[css.td, css.w40]} component="th" scope="row">
                                            <TextareaAutosize
                                                className={css.w90}
                                                rowsMin={3}
                                                placeholder="Commentaire"
                                                onChange={(event) => (dataPresences.eleves[index].commentaire = event.target.value)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <AbsensePrevu />
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(FichePresence);
