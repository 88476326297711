/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @copyright Horizon-Pharma
 */

import React from 'react';
import { connect } from 'react-redux';

import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

const { beforeToday, afterToday } = DateRangePicker;

const annee = new Date().getFullYear();

const periodeDefault = [
    {
        label: "Aujourd'hui",
        value: [new Date(), new Date()],
        placement: 'left',
    },
    {
        label: 'Hier',
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: 'left',
    },
    {
        label: 'Cette semaine',
        value: [startOfWeek(new Date()), endOfWeek(new Date())],
        placement: 'left',
    },
    {
        label: '7 dernier jours',
        value: [subDays(new Date(), 6), new Date()],
        placement: 'left',
    },
    {
        label: '30 dernier jours',
        value: [subDays(new Date(), 29), new Date()],
        placement: 'left',
    },
    {
        label: 'Ce mois',
        value: [startOfMonth(new Date()), endOfMonth(new Date())],
        placement: 'left',
    },
    {
        label: 'Dernier mois',
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left',
    },
    {
        label: 'Cette année',
        value: [new Date(annee, 7, 1), new Date(annee + 1, 6, 31)],
        placement: 'left',
    },
    {
        label: 'Année précédente',
        value: [new Date(annee - 1, 7, 1), new Date(annee, 6, 31)],
        placement: 'left',
    },
    /*{
        label: 'All time',
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
        placement: 'left',
    },
         {
        label: 'Last week',
        closeOverlay: false,
        value: (value) => {
            const [start = new Date()] = value || [];
            return [addDays(startOfWeek(start, { weekStartsOn: 0 }), -7), addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)];
        },
        appearance: 'default',
    },
    {
        label: 'Next week',
        closeOverlay: false,
        value: (value) => {
            const [start = new Date()] = value || [];
            return [addDays(startOfWeek(start, { weekStartsOn: 0 }), 7), addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)];
        },
        appearance: 'default',
    }, */
];

const periodeAfter = [
    {
        label: "Aujourd'hui",
        value: [new Date(), new Date()],
        placement: 'left',
    },
    {
        label: 'Hier',
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: 'left',
    },
    {
        label: 'Cette semaine',
        value: [startOfWeek(new Date()), new Date()],
        placement: 'left',
    },
    {
        label: '7 dernier jours',
        value: [subDays(new Date(), 6), new Date()],
        placement: 'left',
    },
    {
        label: '30 dernier jours',
        value: [subDays(new Date(), 29), new Date()],
        placement: 'left',
    },
    {
        label: 'Ce mois',
        value: [startOfMonth(new Date()), new Date()],
        placement: 'left',
    },
    {
        label: 'Dernier mois',
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left',
    },
    {
        label: 'Cette année',
        value: [new Date(annee, 7, 1), new Date()],
        placement: 'left',
    },
    {
        label: 'Année précédente',
        value: [new Date(annee - 1, 7, 1), new Date(annee, 6, 31)],
        placement: 'left',
    },
];

const DatePickerRange = (props) => {
    let disable;
    let periode;
    switch (props.disabledDate) {
        case 'afterToday':
            disable = afterToday();

            break;
        case 'beforeToday':
            disable = beforeToday();
            periode = periodeAfter;
            break;
        default:
            disable = undefined;
            periode = periodeDefault;
            break;
    }
    return (
        <div className={props.className}>
            <DateRangePicker
                ranges={periode}
                placeholder="Sélectionner une periode"
                style={{ width: 240 }}
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                value={props.value}
                placement={props.placement}
                disabledDate={disable}
            />
        </div>
    );
};

export default connect(null, null)(DatePickerRange);
