export const Modification_information = 237;
export const Enregistre_et_quitter = 236;
export const Lien_atelier = 179;
export const Lien_profil_eleve_Liste_Atelier = 180;
export const Lien_profil_enseignant_Liste_Atelier = 181;
export const Trie_site = 182;
export const Ajout_nouvel_atelier = 183;
export const Lien_le_profil_eleve = 185;
export const Lien_profil_enseignant = 186;
export const Information_eleves = 187;
export const Enregistrer_atelier = 188;
export const Duplication_atelier = 189;
export const Associer_enseignant = 190;
export const Desassocier_enseignant = 191;
export const Associer_eleve = 192;
export const Desassocier_eleve = 193;
export const Nombre_eleve = 194;
export const Presences_élève = 195;
export const Export_excel = 196;
export const Envoi_email = 197;
export const Modifier_eleve = 239;
export const Validation_presences = 199;
export const Supprimer_atelier = 244;
