import { makeStyles } from '@material-ui/core';

const useStylesMaterielUIGlobal = makeStyles((theme) => ({
    /*---------------- CSS Global ----------------*/
    root: {
        marginTop: '0px !important',
        backgroundColor: '#f3f3f4 !important',
    },
    td: {
        padding: '5px !important',
        paddingLeft: '15px !important',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 'calc(50% + 10px) !important',
            display: 'block !important',
        },
    },
    trbody: {
        [theme.breakpoints.down('xs')]: {
            display: 'block !important',
        },
    },
    /*---------------- CSS ClassName ----------------*/
    cBleu: {
        color: '#04336d !important',
    },
    cbleuCheck: {
        color: '#00BFFF !important',
    },
    cRouge: {
        color: '#FF6347 !important',
    },
    cVert: {
        color: '#91c023 !important',
    },
    cVertCheck: {
        color: '#32CD32 !important',
    },
    cVertValidationFrais: {
        color: '#78cf99 !important',
    },
    cWhite: {
        color: '#ffffff !important',
    },
    BcWhite: {
        backgroundColor: '#ffffff !important',
    },
    BcGrisClair: {
        backgroundColor: '#f3f3f4 !important',
    },
    Bc3f51b5: {
        backgroundColor: '#3f51b5 !important',
    },
    BcBleu: {
        backgroundColor: '#2e76b0 !important',
    },
    Bcfce5cd: {
        backgroundColor: '#fce5cd !important',
    },
    Bcf9cb9c: {
        backgroundColor: '#f9cb9c  !important',
    },
    Bcbbd5b0: {
        backgroundColor: '#93c47d !important',
    },
    Bcf28615: {
        backgroundColor: '#f28615 !important',
    },
    Bcf1c232: {
        backgroundColor: '#f1c232 !important',
    },
    Bc6a329f: {
        backgroundColor: '#6a329f !important',
    },
    Bc2986cc: {
        backgroundColor: '#2986cc !important',
    },
    Bc91c023: {
        backgroundColor: '#91c023 !important',
    },
    borderCbleu: {
        borderColor: '#04336d !important',
    },
    borderCvert: {
        borderColor: '#91c023 !important',
    },
    border5: {
        border: '5px solid #f9cb9c !important',
    },
    radius10: {
        borderRadius: '10px !important',
    },
    radius25: {
        borderRadius: '25px !important',
    },
    w200: {
        width: '250% !important',
    },
    w100: {
        width: '100% !important',
    },
    w90: {
        width: '90% !important',
    },
    w80: {
        width: '80% !important',
    },
    w75: {
        width: '75% !important',
    },
    w70: {
        width: '70% !important',
    },
    w60: {
        width: '60% !important',
    },
    w55: {
        width: '55% !important',
    },
    w50: {
        width: '50% !important',
    },
    w45: {
        width: '45% !important',
    },
    w40: {
        width: '40% !important',
    },
    w30: {
        width: '30% !important',
    },
    w33: {
        width: '33% !important',
    },
    w25: {
        width: '25% !important',
    },
    w20: {
        width: '20% !important',
    },
    w15: {
        width: '15% !important',
    },
    w10: {
        width: '10% !important',
    },
    w7: {
        width: '7% !important',
    },
    w5: {
        width: '5% !important',
    },
    w2: {
        width: '2% !important',
    },
    w50em: {
        width: '50em !important',
    },
    wMax875: {
        maxWidth: '875px !important',
    },
    wMax25: {
        maxWidth: '25% !important',
    },
    wMin25: {
        minWidth: '25% !important',
    },
    h200: {
        height: '200px',
    },
    h20: {
        height: '20%',
    },
    h80: {
        height: '80%',
    },
    h100: {
        height: '100%',
    },
    h60: {
        height: '60%',
    },
    h40: {
        height: '40%',
    },
    wMin30: {
        minWidth: '30% !important',
    },
    textCenter: {
        textAlign: 'center',
    },
    overflowAuto: { overflow: 'auto !important' },
    nomargin: {
        margin: '0 !important',
    },
    positionFixed: { position: 'fixed !important' },
    marginAuto: { margin: 'auto' },
    m2: { margin: '2% !important' },
    m10: { margin: '10% !important' },
    mt0: {
        marginTop: '0 !important',
    },
    mt1: {
        marginTop: '1% !important',
    },
    mt2: {
        marginTop: '2% !important',
    },
    mt2_5: {
        marginTop: '2.5% !important',
    },
    mt5: {
        marginTop: '5% !important',
    },
    mt15: {
        marginTop: '15% !important',
    },

    mt25: {
        marginTop: '25% !important',
    },

    mb1: {
        marginBottom: '1% !important',
    },
    mb2: {
        marginBottom: '2% !important',
    },
    mb5: {
        marginBottom: '5% !important',
    },
    mb10: {
        marginBottom: '10% !important',
    },
    mb15: {
        marginBottom: '15% !important',
    },
    mb8px: {
        marginBottom: '8px !important',
    },
    mr1: {
        marginRight: '1% !important',
    },
    mr2: {
        marginRight: '2% !important',
    },
    mr5: {
        marginRight: '5% !important',
    },
    mr20: {
        marginRight: '20% !important',
    },
    mr40: {
        marginRight: '40% !important',
    },
    mr30px: {
        marginRight: '30px !important',
    },
    mr10px: {
        marginRight: '10px !important',
    },
    ml2: {
        marginLeft: '2% !important',
    },
    ml5: {
        marginLeft: '5% !important',
    },
    ml10: {
        marginLeft: '10% !important',
    },
    ml15: {
        marginLeft: '15% !important',
    },
    ml20: {
        marginLeft: '20% !important',
    },
    ml0_3em: {
        marginLeft: '0.3em !important',
    },
    ml2_5em: {
        marginLeft: '2.5em !important',
    },
    ml2em: {
        marginLeft: '2em !important',
    },
    ml30px: {
        marginLeft: '30px !important',
    },
    ml10px: {
        marginLeft: '10px !important',
    },
    ml50: {
        marginLeft: '50% !important',
    },
    ml70: {
        marginLeft: '70% !important',
    },
    p0: {
        padding: '0px !important',
    },
    p2: {
        padding: '2% !important',
    },
    p3: {
        padding: '3% !important',
    },
    pt1: {
        paddingTop: '1% !important',
    },
    pl1: {
        paddingLeft: '1% !important',
    },
    pr1: {
        paddingRight: '1% !important',
    },
    pb1: {
        paddingBottom: '1% !important',
    },
    pr1em: {
        paddingRight: '1em !important',
    },
    pl1em: {
        paddingLeft: '1em !important',
    },
    p2px: {
        padding: '2px !important',
    },
    overflowX: {
        overflowX: 'auto !important',
    },
    none: { display: 'none' },
    flex: {
        display: 'flex !important',
    },
    block: {
        display: 'block !important',
    },
    contents: {
        display: 'contents !important',
    },
    directionColumn: {
        flexDirection: 'column !important',
    },
    grow1: { flexGrow: 1 },
    center: {
        justifyContent: 'center',
    },
    alignCenter: {
        alignItems: 'center !important',
    },
    alignTop: {
        verticalAlign: 'top !important',
    },
    alignBaseline: {
        verticalAlign: 'baseline !important',
    },
    directionRow: {
        flexDirection: 'row !important',
    },

    fontBold: {
        fontWeight: 'bold',
    },
    textLeft: {
        textAlign: 'left !important',
    },
    textRight: {
        textAlign: 'right !important',
    },
    floatRight: {
        float: 'right !important',
    },
    floatLeft: {
        float: 'left !important',
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    spaceAround: {
        justifyContent: 'space-around',
    },
    fontSize1em: {
        fontSize: '1em !important',
    },
    fontSize0_9em: {
        fontSize: '0.9em !important',
    },
    fontSize0_7em: {
        fontSize: '0.7em !important',
    },
    fontSize2em: {
        fontSize: '2rem',
    },
    fontSize12px: {
        fontSize: '12px !important',
    },
    fontSize14px: {
        fontSize: '14px !important',
    },

    /* ---------responsive--------- */
    xs_w100: {
        [theme.breakpoints.down('xs')]: {
            width: '100% !important',
        },
    },
    xs_w90: {
        [theme.breakpoints.down('xs')]: {
            width: '90% !important',
        },
    },
    xs_w80: {
        [theme.breakpoints.down('xs')]: {
            width: '80% !important',
        },
    },
    xs_w50: {
        [theme.breakpoints.down('xs')]: {
            width: '50% !important',
        },
    },
    xs_w60: {
        [theme.breakpoints.down('xs')]: {
            width: '60% !important',
        },
    },

    wMax100: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100% !important',
        },
    },
    xs_nomargin: {
        [theme.breakpoints.down('xs')]: {
            margin: '0 !important',
        },
    },
    xs_p0: {
        [theme.breakpoints.down('xs')]: {
            padding: '0 !important',
        },
    },
    xs_mt2: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '2% !important',
        },
    },
    xs_mt5: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '5% !important',
        },
    },
    xs_mb2: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '2% !important',
        },
    },
    xs_mb10: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '10% !important',
        },
    },
    xs_mr1: {
        [theme.breakpoints.down('xs')]: {
            marginRight: '1% !important',
        },
    },
    xs_mr5: {
        [theme.breakpoints.down('xs')]: {
            marginRight: '5% !important',
        },
    },
    xs_mb5: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '5% !important',
        },
    },
    xs_ml2: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: '2% !important',
        },
    },
    xs_pl2_Important: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '2% !important',
        },
    },
    xs_Left0: {
        [theme.breakpoints.down('xs')]: {
            left: '0px !important',
        },
    },
    xs_textLeft: {
        [theme.breakpoints.down('xs')]: {
            textalign: 'left !important',
        },
    },
    xs_block: {
        [theme.breakpoints.down('xs')]: {
            display: 'block !important',
        },
    },
    xs_none: {
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },
    xs_floatLeft: {
        [theme.breakpoints.down('xs')]: {
            float: 'left !important',
        },
    },
    xs_floatRight: {
        [theme.breakpoints.down('xs')]: {
            float: 'right !important',
        },
    },
    xs_overflowInitial: {
        [theme.breakpoints.down('xs')]: {
            overflowWrap: 'initial !important',
        },
    },
    xs_directionColumn: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column !important',
        },
    },
    /*---------------- CSS ClassName Personalisé----------------*/
    smallContainer: {
        margin: '70px 5% 0 5% !important',
        backgroundColor: '#ffffff !important',
        [theme.breakpoints.down('xs')]: {
            margin: '0% !important',
        },
    },
    smallContainerAgent: {
        margin: '111px 5% 0 5% !important',
        backgroundColor: '#ffffff !important',
        [theme.breakpoints.down('xs')]: {
            margin: '0% !important',
        },
    },
    textareaML30pxW50: {
        width: '50% !important',
        marginLeft: '30px !important',
    },
    textareaML30pxW80MB1emMT0: {
        marginLeft: '30px !important',
        width: '80% !important',
        marginBottom: '1em !important',
        marginTop: '0px !important',
    },
    textareaW90M0P0: {
        margin: '0 !important',
        width: '90% !important',
        padding: '0 !important',
    },
    textareaW40ML2_XSW90XSMT2: {
        width: '40% !important',
        marginLeft: '2% !important',
        [theme.breakpoints.down('xs')]: {
            width: '90% !important',
            marginTop: '2% !important',
        },
    },
    linkW100MR2: {
        marginRight: '2%',
        whiteSpace: 'nowrap',
    },
    linkRight: {
        textAlign: 'right !important',
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: '0 2px',
        color: 'inherit',
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: '#676767',
    },
}));

export default useStylesMaterielUIGlobal;
