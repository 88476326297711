export const Lien_profil = 138;
export const Lien_services_Liste_Agent = 139;
export const Lien_cours_Liste_Agent = 140;
export const Trie_site = 141;
export const Ajout_nouvel_agent = 142;
export const Usurpation_agent = 143;
export const Envoi_email = 144;
export const Export_excel = 145;
export const Lien_services = 147;
export const Lien_cours = 148;
export const Modifcation_agent = 152;
export const Modifcation_mot_de_passe = 154;
export const Modification_actif_role_vehicule = 233;
export const Suppression_agent = 234;
export const Filtrage_liste = 235;
export const connexion = 150;
export const vehicule = 151;

export const MonCompte_connexion = 247;
export const MonCompte_vehicule = 248;
export const MonCompte_Enregistrment = 249;
export const MonCompte_Modification_mot_de_passe = 251;
export const MonCompte_Modification_general = 269;
export const MonCompte_Modification_actif_role_véhicule = 270;
