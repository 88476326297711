import extractModeReglement from '../../Utils/extractModeReglement';
import ExportExcel from '../ExportTableau/exportExcel';

/* --------------Export des cotisationq et reglement ------------------------ */

export function ExportCotisation(props) {
    const headerExport = [
        { label: 'Nom', value: 'nomPrenomUtilisateur' },
        { label: 'Email', value: 'email' },
        { label: 'Site', value: 'libelleSite' },
        { label: 'Montant Total', value: 'montantTotal' },
        { label: 'Montant Réglé', value: 'MontantDejaRegle' },
        { label: 'Mode de reglement', value: 'modeReglement' },
        { label: "Numero d'abonnement", value: 'abonnement' },
        { label: 'Solde restant', value: 'soldeRestant' },
    ];
    const tableauExport = [];
    switch (props.label) {
        case 'Toutes les cotisations':
            for (let index = 0; index < props.data.length; index++) {
                tableauExport.push({
                    nomPrenomUtilisateur: props.data[index].nomPrenomUtilisateur,
                    email: props.data[index].email,
                    libelleSite: props.data[index].libelleSite,
                    montantTotal: props.data[index].montantTotal,
                    MontantDejaRegle: props.data[index].MontantDejaRegle,
                    modeReglement: extractModeReglement(props.data[index].concatCotisation),
                    abonnement: props.data[index].abonnement,
                    soldeRestant: +props.data[index].montantTotal - +props.data[index].MontantDejaRegle,
                });
            }
            break;
        case 'Règlements complets':
            for (let index = 0; index < props.data.length; index++) {
                const montantRestant = +props.data[index].montantTotal - +props.data[index].MontantDejaRegle;
                if (montantRestant === 0) {
                    tableauExport.push({
                        nomPrenomUtilisateur: props.data[index].nomPrenomUtilisateur,
                        email: props.data[index].email,
                        libelleSite: props.data[index].libelleSite,
                        montantTotal: props.data[index].montantTotal,
                        MontantDejaRegle: props.data[index].MontantDejaRegle,
                        modeReglement: extractModeReglement(props.data[index].concatCotisation),
                        abonnement: props.data[index].abonnement,
                        soldeRestant: +props.data[index].montantTotal - +props.data[index].MontantDejaRegle,
                    });
                }
            }
            break;
        case 'Règlements partiels':
            for (let index = 0; index < props.data.length; index++) {
                const montantRestant = +props.data[index].montantTotal - +props.data[index].MontantDejaRegle;
                if (montantRestant > 0) {
                    tableauExport.push({
                        nomPrenomUtilisateur: props.data[index].nomPrenomUtilisateur,
                        email: props.data[index].email,
                        libelleSite: props.data[index].libelleSite,
                        montantTotal: props.data[index].montantTotal,
                        MontantDejaRegle: props.data[index].MontantDejaRegle,
                        modeReglement: extractModeReglement(props.data[index].concatCotisation),
                        abonnement: props.data[index].abonnement,
                        soldeRestant: +props.data[index].montantTotal - +props.data[index].MontantDejaRegle,
                    });
                }
            }
            break;
        case 'Trop-perçus':
            for (let index = 0; index < props.data.length; index++) {
                const montantRestant = +props.data[index].montantTotal - +props.data[index].MontantDejaRegle;
                if (montantRestant < 0) {
                    tableauExport.push({
                        nomPrenomUtilisateur: props.data[index].nomPrenomUtilisateur,
                        email: props.data[index].email,
                        libelleSite: props.data[index].libelleSite,
                        montantTotal: props.data[index].montantTotal,
                        MontantDejaRegle: props.data[index].MontantDejaRegle,
                        modeReglement: extractModeReglement(props.data[index].concatCotisation),
                        abonnement: props.data[index].abonnement,
                        soldeRestant: +props.data[index].montantTotal - +props.data[index].MontantDejaRegle,
                    });
                }
            }
            break;
        default:
            break;
    }

    return <ExportExcel data={tableauExport} head={headerExport} nomFichier={props.nomFichier} label={props.label} />;
}
