/* eslint-disable react-hooks/exhaustive-deps */
/**
 * liste eleves
 *
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    IconButton,
    InputLabel,
    FormControl,
    Select,
    Paper,
    Dialog,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Container from '../../components/atoms/Container/container';
import { POST, GET, DELETE } from '../../components/Utils/requete';
import PaginationTable from '../../components/organism/Table/Pagination';
import TableRecherche from '../../components/organism/Table/TableRecherche';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import SelectMultiple from '../../components/organism/SelectMultiple/SelectMultiple';
import ButtonCustom from '../../components/atoms/ButtonCustom';
import Reduire from '../../components/organism/Reduire/Reduire';
import Contact from '../../components/organism/Contact/Contact';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import CustomTooltips from '../../components/organism/Tooltip/customTooltip';
import setSelect from '../../components/Utils/setSelect';
import { testDroit } from '../../components/Utils/testDroit';
import ExportExcel from '../../components/organism/ExportTableau/exportExcel';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { tableHead, requeteGETFiltre, requetePOSTFiltre, tableauSaveFiltre, filtreSimple, tableHeadExport } from './consts';

import * as fonctionEleve from '../../fonctionsRoles/eleve';

const ListeEleves = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const css = useStylesMaterielUIGlobal();
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const selectMultiple = useSelector((state) => state.SelectMultiple);
    const refreshMultiple = useSelector((state) => state.SelectMultiple.refreshMultiple);
    const saveFiltre = useSelector((state) => state.SaveFiltre.listeEleves);
    const selectSimple = useSelector((state) => state.SelectSimple);
    const [dataEleve, setDataEleve] = useState([]);
    const [etatAbandon, setEtatAbandon] = useState(0);
    const [tableauFiltre, setTableauFiltre] = useState({
        Offre: [],
        Cycle: [],
        Statut: [],
        AnneeScolaire: [],
        Annee: [],
        Site: [],
        Instrument: [],
        Style: [],
    });
    const [deleteRefresh, setDeleteRefresh] = useState(false);
    const [open, setOpen] = useState(false);
    const [delIdEleve, setDelIdEleve] = useState();
    const [mail, setMail] = useState([]);
    const [mailSelect, setMailS] = useState([]);
    const [selected, setSelected] = useState([]);

    const handleClick = (name, email) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
        let selectMail = mailSelect;
        for (let index = 0; index < email.split('|-|').length; index++) {
            selectMail.push(email.split('|-|')[index].split('|')[2]);
        }
        setMailS(selectMail);
    };

    function Button(props) {
        if (testDroit(user[usurpation].roleFonction, fonctionEleve.Suppression_eleve)) {
            const link = function (button) {
                return props.url ? <Link to={'/fiche-eleve/0'}>{button}</Link> : <>{button}</>;
            };
            if (props.type === 'icone') {
                return (
                    <CustomTooltips title={"supprimer l'élève"}>
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                setDelIdEleve(props.idEleve);
                                setOpen(true);
                            }}
                            className={[css.p0, css.floatRight]}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    </CustomTooltips>
                );
            }
            return link(
                <ButtonCustom
                    variant="outlined"
                    size="small"
                    color={props.suppression ? 'secondary' : 'primary'}
                    onClick={props.onClick}
                    className={[css.xs_w100, css.xs_mb2]}
                >
                    {props.suppression ? props.suppression : props.label}
                </ButtonCustom>,
            );
        }
        return <></>;
    }

    async function recupDataEleves() {
        const data = {
            fk_anneeScolaire: selectSimple.AnneeScolaire,
            fk_site: selectMultiple.Site,
            fk_offre: selectMultiple.Offre,
            fk_cycle: selectSimple.Cycle,
            fk_annee: selectSimple.Annee,
            fk_instrument: selectSimple.Instrument,
            fk_style: selectSimple.Style,
            fk_statut: selectSimple.Statut,
        };
        // eslint-disable-next-line eqeqeq
        if (etatAbandon != 2) {
            data.etatAbandon = etatAbandon;
        }
        const response = await POST('/api/eleves/list/', data);
        let tableauMail = [];
        for (let elem = 0; elem < response.data.length; elem++) {
            const contact = response.data[elem].concatInfoResponsable.split('|-|');
            for (let index = 0; index < contact.length; index++) {
                tableauMail.push(contact[index].split('|')[2]);
            }
        }
        setMail(tableauMail);
        setDataEleve(response.data);
    }

    async function recupFiltre() {
        if (tableauFiltre.AnneeScolaire.length === 0) {
            for (let elem = 0; elem < requeteGETFiltre.length; elem++) {
                const requete = await GET(requeteGETFiltre[elem].url);
                setTableauFiltre((prevState) => ({
                    ...prevState,
                    [Object.keys(tableauFiltre)[elem]]: requete.data,
                }));
            }
            for (let elem = 0; elem < requetePOSTFiltre.length; elem++) {
                const requete = await POST(requetePOSTFiltre[elem].url);
                setTableauFiltre((prevState) => ({
                    ...prevState,
                    [Object.keys(tableauFiltre)[elem + 6]]: requete.data,
                }));
            }
        }
    }

    async function supprimerEleve(idEleve) {
        await DELETE(`/api/eleves/${idEleve}`);
        setDeleteRefresh(!deleteRefresh);
        setOpen(false);
    }

    function ViewInstrumentDateCreation(props) {
        const splitparSI = props.chaine.split('|-|');
        const elemAAfficher = [];
        for (let index = 0; index < splitparSI.length; index++) {
            const splitParElem = splitparSI[index].split('|');
            elemAAfficher.push(
                <>
                    <span>{`${DateHeureFormat({ date: splitParElem[1] })} ${splitParElem[0]}`}</span>
                    <br />
                </>,
            );
        }

        return <>{elemAAfficher}</>;
    }
    useEffect(() => {
        recupFiltre();
        setSelect({
            saveFiltre: saveFiltre,
            tableauSaveFiltre: tableauSaveFiltre,
            user: user,
            selectMultiple: selectMultiple,
            selectSimple: selectSimple,
            filtre: props.filtre,
            filtreMultiple: props.filtreMultiple,
        });
        props.addNomPage('Liste des élèves');
        if (testDroit(user[usurpation].roleFonction, fonctionEleve.Trie_site)) {
            const siteAtenne = [];
            for (let index = 0; index < user[usurpation].antennes.length; index++) {
                siteAtenne.push(user[usurpation].antennes[index].idSite);
            }
            props.filtreMultiple([{ Site: siteAtenne }]);
        }
    }, []);

    useEffect(() => {
        recupDataEleves();
        props.resetPagination();
    }, [refreshSimple, refreshMultiple, deleteRefresh, etatAbandon]);

    return (
        <div className="listeContainer">
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                maxWidth="lg"
            >
                <DialogContent>
                    <DialogContentText>
                        <span className="mr2">Voulez-vous supprimer cet élève ?</span>
                        <div className="mt2 textCenter">
                            <Button
                                label={'Retour'}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            />
                            <Button
                                onClick={() => {
                                    supprimerEleve(delIdEleve);
                                }}
                                suppression={'Suppression'}
                            />
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Container>
                <Reduire>
                    <div className="xs_flex xs_directionColumn xs_mb5">
                        <FormControl className={[css.mr2, css.ml2, css.w25, css.xs_w90]}>
                            <InputLabel>Etat abandon</InputLabel>
                            <Select
                                value={etatAbandon}
                                onChange={(event) => {
                                    setEtatAbandon(event.target.value);
                                }}
                            >
                                <option value={0}>Uniquement sans abandon</option>
                                <option value={2}>tous les élèves</option>
                                <option value={1}>Uniquement les élèves ayant abandonnés</option>
                            </Select>
                        </FormControl>
                        <SelectSimple
                            tableau={tableauFiltre.AnneeScolaire}
                            inputLabel={'Année Scolaire'}
                            indice={'AnneeScolaire'}
                            liste={'eleve'}
                            classe={[css.mr2, css.ml2, css.w25, css.xs_w90]}
                        ></SelectSimple>
                        <SelectMultiple
                            tableau={tableauFiltre.Offre}
                            inputLabel={'Offre'}
                            indice={'Offre'}
                            classe={[css.mr2, css.ml2, css.w25, css.xs_w90]}
                            liste={'eleve'}
                        ></SelectMultiple>
                        {filtreSimple.map((item, i) => (
                            <SelectSimple
                                tableau={tableauFiltre[item.indice]}
                                inputLabel={item.label}
                                indice={item.indice}
                                liste={'eleve'}
                                classe={[css.mr2, css.ml2, css.w25, css.xs_w90]}
                                key={i}
                            ></SelectSimple>
                        ))}
                        <SelectMultiple
                            tableau={tableauFiltre.Site}
                            inputLabel={'Site'}
                            indice={'Site'}
                            classe={[css.mr2, css.ml2, css.w25, css.xs_w90]}
                            liste={'eleve'}
                            disabled={testDroit(user[usurpation].roleFonction, fonctionEleve.Trie_site)}
                        ></SelectMultiple>
                    </div>
                    <div className="mt2 flex center xs_directionColumn">
                        <Button
                            suppression={'Supprimer les Filtres'}
                            onClick={() => {
                                props.removeFiltreMultiple();
                                props.removeFiltre();
                                props.removeSaveFiltre();
                                props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
                            }}
                        />
                        {testDroit(user[usurpation].roleFonction, fonctionEleve.Ajout_nouvel_eleve_liste_eleve) ? (
                            <Button label={'Ajouter un élève'} url={true} />
                        ) : null}
                        {testDroit(user[usurpation].roleFonction, fonctionEleve.Envoi_email) ? (
                            <>
                                <Button
                                    label={<Contact email={mailSelect} lib="Email à la selection" />}
                                    onClick={() => {
                                        setSelected([]);
                                        setMailS([]);
                                    }}
                                />
                                {}
                                <Button label={<Contact email={mail} lib="Email à tous" />} />
                                <Button
                                    label={<Contact email={mail} pressPapier={true} />}
                                    onClick={() =>
                                        props.notification({
                                            message: 'Les emails ont été copié dans le presse papier.',
                                            status: true,
                                        })
                                    }
                                />
                            </>
                        ) : null}
                        <ExportExcel data={dataEleve} head={tableHeadExport} nomFichier="Liste éléves" />
                    </div>
                </Reduire>
                <div className="flex spaceBetween alignICenter mb2 xs_directionColumn xs_alignIBaseline">
                    <TableRecherche
                        tableau={dataEleve}
                        className={[css.w50, css.xs_w100]}
                        indice={['nomPrenomEleve', 'instrument', 'concatInfoResponsable']}
                    ></TableRecherche>
                    <PaginationTable data={props.recherche.fn(dataEleve)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHead}></TableHeadCustom>
                        <TableBody>
                            {setData(props.recherche.fn, dataEleve, props.Page[0].page, props.Page[0].rowsPerPage, props.orderState, props.orderByState).map(
                                (row, index) => {
                                    const button = <Button type={'icone'} idEleve={row.idEleve} />;
                                    return (
                                        <>
                                            <TableRow className={css.trbody} key={row.nomPrenomEleve} hover selected={isSelected(row.nomPrenomEleve)}>
                                                <TableCell
                                                    className={css.td}
                                                    component="th"
                                                    scope="row"
                                                    onClick={() => handleClick(row.nomPrenomEleve, row.concatInfoResponsable)}
                                                >
                                                    <div>
                                                        <span>Élèves</span>
                                                        <span>{window.matchMedia('(max-width: 576px)').matches ? button : null}</span>
                                                    </div>
                                                    {window.matchMedia('(max-width: 576px)').matches ? null : button}
                                                    {testDroit(user[usurpation].roleFonction, fonctionEleve.Lien_profil_eleve) ? (
                                                        <CustomTooltips title="Éditer l'élève">
                                                            <Link to={`/fiche-eleve/${row.idEleve}`}>{row.nomPrenomEleve}</Link>
                                                        </CustomTooltips>
                                                    ) : null}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Naissance</div>
                                                    <DateHeureFormat date={row.dateNaissance} />
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Instrument</div>
                                                    {row.instrument.split(',').map((e, i) => (
                                                        <ViewInstrumentDateCreation key={i} chaine={e} />
                                                    ))}
                                                </TableCell>
                                                <TableCell className={[css.td, css.fontSize12px]} component="th" scope="row">
                                                    <div>Contact(s)</div>
                                                    {row.telephoneMobile ? <Contact mobile={row.telephoneMobile} nomPrenom={row.nomPrenomEleve} /> : null}
                                                    {row.concatInfoResponsable.split('|-|').map((e, i) => {
                                                        return (
                                                            <Contact
                                                                key={i}
                                                                email={e.split('|')[2]}
                                                                idresponsable={[e.split('|')[0]]}
                                                                mobile={e.split('|')[3]}
                                                                telephone={e.split('|')[4]}
                                                                nomPrenom={e.split('|')[1]}
                                                                idFonction={fonctionEleve.Lien_profil_responsable_liste_eleve}
                                                            />
                                                        );
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                },
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationTable data={props.recherche.fn(dataEleve)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        removeFiltre() {
            dispatch({ type: 'removeFiltre' });
        },
        removeFiltreMultiple() {
            dispatch({ type: 'removeFiltreMultiple' });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        filtreMultiple(filtreMultiple) {
            dispatch({ type: 'multiple', filtreMultiple });
        },
        removeSaveFiltre() {
            dispatch({ type: 'removeSave', liste: 'eleve' });
        },
        resetPagination() {
            dispatch({ type: 'resetPagination' });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ListeEleves);
