/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author viaud Benjamin
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import { Button } from '@material-ui/core';

// import { listeBouttons } from './const';
import DialogCustom from '../../components/organism/Dialog/dialog';
import Input from '../../components/atoms/TextField';
import { GET, POST } from '../../components/Utils/requete';
import UserInactif from '../../components/organism/UserInactif/UserInactif';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const ViewTable = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const css = useStylesMaterielUIGlobal();
    const cookiesBDD = Cookies.get('GestionBDD') ? JSON.parse(Cookies.get('GestionBDD')) : false;
    const [openModal, setOpenModal] = useState(!cookiesBDD ? true : false);
    const [dataInput, setDataInput] = useState({ email: '', password: '' });
    const history = useHistory();
    async function connexion() {
        const data = {
            email: dataInput.email,
            password: dataInput.password,
        };
        let response = await POST('/api/login', data, { redirectOnError: false });
        if (response.success === true) {
            response = await GET(`/bdd/liste?idUtilisateur=${user[usurpation].fk_id}`);
            const tableauAcces = [];
            const verifAcces = [];
            for (let index = 0; index < response.data.length; index++) {
                tableauAcces.push({
                    data: response.data[index].categorie.boutons,
                    titre: response.data[index].categorie.titre,
                });
                for (let elem = 0; elem < response.data[index].categorie.boutons.length; elem++) {
                    verifAcces.push(response.data[index].categorie.boutons[elem].url);
                }
            }
            response = await GET('/api/parametres/list?categorie=gestionBDD', undefined, {
                redirectOnError: false,
            });
            let delaiParametre;
            if (response) {
                const index = response.data.findIndex((e) => e.cle === 'delaiMDP_GestionBDD');
                if (index >= 0) {
                    delaiParametre = response.data[index].valeur;
                }
            }
            const calculDelai = +delaiParametre !== 0 ? (1 / 24 / 60) * delaiParametre : 1;
            Cookies.set(
                'GestionBDD',
                {
                    bouton: tableauAcces,
                    email: data.email,
                    verifAcces: verifAcces,
                },
                { expires: calculDelai },
            );
            setOpenModal(false);
        } else {
            switch (response.message) {
                case 'Password ou email incorrect':
                    props.notification({ message: response.message });
                    break;
                case "Vous n'êtes pas autorisé":
                    props.notification({ message: response.message });
                    history.push('/accueil');
                    break;
                default:
                    break;
            }
        }
    }
    function updateState(keys, valueI) {
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    function Modal() {
        return (
            <DialogCustom
                open={openModal}
                className={[css.radius25]}
                onClose={() => {
                    history.push('/accueil');
                }}
                children={
                    <div className="flex directionColumn">
                        <Input
                            label="Email"
                            name="email"
                            value={dataInput.email}
                            onChange={(event) => updateState('email', event.target.value)}
                        />
                        <Input
                            label="Mot de passe"
                            type="password"
                            name="password"
                            value={dataInput.password}
                            onChange={(event) => updateState('password', event.target.value)}
                            onKeyPress={(event) => (event.key === 'Enter' ? connexion() : null)}
                        />
                        <Button className={css.mt2} color="primary" onClick={() => connexion()}>
                            Connexion
                        </Button>
                        <Button type="submit" className={css.mt2} color="secondary" onClick={() => history.push('/accueil')}>
                            Retour a l'accueil
                        </Button>
                    </div>
                }
            />
        );
    }
    useEffect(() => {
        props.addNomPage('Gestion de la base de donnée');
    }, []);
    if (window.matchMedia('(max-width: 576px)').matches) {
        return <p className="textCenter ">Vous ne pouvez pas acceder à cette page sur mobile</p>;
    }
    return (
        <div className="listeContainer">
            <UserInactif url={'/accueil'} />
            {Modal()}
            {cookiesBDD
                ? cookiesBDD.bouton.map((row, i) => {
                      return (
                          <div key={i} className="mr5">
                              <h3 className="mb0 ">{row.titre}</h3>
                              <hr></hr>
                              <div className="grid grid3Column">
                                  {row.data.map((row, i) => (
                                      <Link key={i} to={`/viewTableData?${row.url}&${row.label}`} className="mr5 mb5">
                                          <Button variant="outlined" className={css.w100} color="primary">
                                              {row.label}
                                          </Button>
                                      </Link>
                                  ))}
                              </div>
                          </div>
                      );
                  })
                : null}
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(ViewTable);
