/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Tabs, Tab, Checkbox, Button } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { GET, POST } from '../../components/Utils/requete';

import Input from '../../components/atoms/TextField';
import TabPanel from '../../components/organism/Onglet/TabPanel';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import TelephoneInput from '../../components/organism/Input/telephone';
import InfoAgent from '../../components/organism/InfoAgent/infoAgent';
import creationTableauFiltre from '../../components/Utils/createTableauFiltre';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { carburant } from './consts';
import * as fonctionAgent from '../../fonctionsRoles/agents';

const FicheAgent = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    let { id } = useParams();
    const [value, setValue] = useState(0);
    const [dataInput, setDataInput] = useState({
        nom: '',
        prenom: '',
        adresse1: '',
        adresse2: '',
        codePostal: '',
        ville: '',
        email: '',
        telephoneMobile: '',
        telephoneFixe: '',
        password: '',
        confimationPassword: '',
        vehiculeMarque: '',
        vehiculeModele: '',
        vehiculePuissance: 0,
        vehiculeImmatriculation: '',
        vehiculeCarburant: '',
    });
    const [checkBoxActif, setCheckBoxActif] = useState(false);
    const [checkBoxPartitions, setCheckBoxPartitions] = useState(false);
    const [antenne, setAntenne] = useState();
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const [listeGroupeRole, setListeGroupeRole] = useState([]);
    const modification_onglet_general = !verificationDroit(fonctionAgent.MonCompte_Modification_general, fonctionAgent.Modifcation_agent);
    const disabledChamps = !verificationDroit(fonctionAgent.MonCompte_Modification_actif_role_véhicule, fonctionAgent.Modification_actif_role_vehicule);
    const css = useStylesMaterielUIGlobal();
    function BoutonSave(props) {
        if (verificationDroit(fonctionAgent.MonCompte_Enregistrment, fonctionAgent.Modifcation_agent)) {
            return (
                <div className="mt2 xs_flex xs_flexColumn">
                    <Link to="/liste-agents">
                        <Button variant="outlined" color="secondary" className={[css.mr2, css.xs_w90, css.xs_mb2]}>
                            Retour
                        </Button>
                    </Link>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={[css.mr2, css.xs_nomargin, css.xs_mb2, css.xs_w90]}
                        onClick={() => saveAgent(props.onglet)}
                    >
                        Enregistrer
                    </Button>
                </div>
            );
        } else {
            return <></>;
        }
    }
    function updateInput(keys, valueI) {
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function ViewAntenne(props) {
        if (props.data !== undefined) {
            if (props.data.length === 0) {
                return <></>;
            }
            return (
                <>
                    <h3 className="mb0 xs_textCenter">Responsabilité</h3>
                    <hr></hr>
                    {props.data.map((row, i) => (
                        <div key={i} className="w80 ml10">
                            <div className="flex textRight alignICenter mb10px">
                                <span>
                                    {`Antenne/Site : ${row.libelleAntenne}`}
                                    {row.libelleAntenne === row.libelleSite ? '' : `/${row.libelleSite}`}
                                </span>
                            </div>
                            <div className="flex textRight alignICenter mb10px">
                                <span>{`Role : ${row.role}`}</span>
                            </div>
                            <hr className="gray"></hr>
                        </div>
                    ))}
                </>
            );
        }
        return <></>;
    }
    function verificationDroit(Mesdroit, autreDroit) {
        if (+user[usurpation].fk_id !== +id) return testDroit(user[usurpation].roleFonction, autreDroit);
        return testDroit(user[usurpation].roleFonction, Mesdroit);
    }
    async function recupDataAgent() {
        const response = await GET('/api/utilisateursAdministratifs/' + id);
        for (let elem = 0; elem < Object.keys(response.data).length; elem++) {
            updateInput(Object.keys(response.data)[elem], Object.values(response.data)[elem]);
        }
        props.addNomPage(`Profil agent - ${response.data.nom} ${response.data.prenom}`);
        props.filtre([{ Roles: response.data.roleFonction }, { Carburant: response.data.vehiculeCarburant }, { Site: response.data.fk_siteResidence }]);
        setAntenne(response.data.antennes);
        if (response.data.gestionPartitions === 1) {
            setCheckBoxPartitions(true);
        }
        if (response.data.actif === 1) {
            setCheckBoxActif(true);
        }
    }
    async function recupfiltre() {
        const response = await GET(`/api/gestionRole/listeGroupe?lecture=true`);
        setListeGroupeRole(creationTableauFiltre(response.data, 'idGroupe', 'libelleGroupe'));
    }
    async function saveAgent(events) {
        const data = {
            idUtilisateur: id === '0' ? null : id,
            actif: checkBoxActif === true ? 1 : 0,
            nom: dataInput.nom,
            prenom: dataInput.prenom,
            gestionPartitions: checkBoxPartitions === true ? 1 : 0,
            adresse1: dataInput.adresse1,
            adresse2: dataInput.adresse2,
            codePostal: dataInput.codePostal,
            ville: dataInput.ville,
            telephoneFixe: dataInput.telephoneFixe,
            telephoneMobile: dataInput.telephoneMobile,
            vehiculeMarque: dataInput.vehiculeMarque,
            vehiculeModele: dataInput.vehiculeModele,
            vehiculePuissance: dataInput.vehiculePuissance !== '' ? dataInput.vehiculePuissance : '0',
            vehiculeImmatriculation: dataInput.vehiculeImmatriculation,
            vehiculeCarburant: reducerFiltre.Carburant,
            fk_siteResidence: reducerFiltre.Site === '' ? null : reducerFiltre.Site,
            email: dataInput.email,
            role: 'Agent',
            fk_groupe: reducerFiltre.Roles,
        };
        if (id === '0') {
            data.password = 'password';
        }
        let dataOK = true;
        if ((!data.nom || !data.prenom || !data.role || !data.email, !data.fk_groupe)) {
            props.notification({
                message: 'Veuillez remplir les champs nom, prenom, email et rôle',
            });
            dataOK = false;
        }
        if (events === 'password') {
            if (dataInput.password === dataInput.confimationPassword) {
                data.password = dataInput.password;
            } else {
                dataOK = false;
                props.notification({
                    message: 'Veuillez fournir la même valeur.',
                });
            }
        }
        if (dataOK === true) {
            const response = await POST('/api/utilisateursAdministratifs/' + id, data, {
                redirectOnError: false,
            });
            if (response.success) {
                props.notification({
                    message: "L'agent a bien été enregistré",
                    status: response.success,
                });
                props.history.push(`/fiche-agent/${response.data.idUtilisateur}`);
            } else {
                props.notification({
                    message: `Une erreur est survenue lors de l'enregistrement : ${response.message}`,
                });
            }
        }
    }
    useEffect(() => {
        if (id === '0') {
            props.addNomPage(`Nouvel agent`);
            props.removeFiltre();
            setCheckBoxActif(false);
            setCheckBoxPartitions(false);
            for (let elem = 0; elem < Object.keys(dataInput).length; elem++) {
                updateInput(Object.keys(dataInput)[elem], '');
            }
        }
        recupfiltre();
        if (id !== '0') {
            recupDataAgent();
        }
    }, [id]);
    function positionTopBarre() {
        if (testDroit(user[usurpation].roleFonction, fonctionAgent.Lien_cours) || testDroit(user[usurpation].roleFonction, fonctionAgent.Lien_services))
            return '111px';
        return '63px';
    }
    return (
        <div className="ficheContainer mt47px">
            <InfoAgent idUtilisateur={id} type="profil" idCours={fonctionAgent.Lien_cours} idService={fonctionAgent.Lien_services} />
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                style={{ position: 'fixed', width: 'calc(100% - 210px)', zIndex: '2', top: positionTopBarre() }}
            >
                <Tab label="General" />
                <Tab disabled={!verificationDroit(fonctionAgent.MonCompte_connexion, fonctionAgent.connexion)} label="Connexion" />
                <Tab disabled={!verificationDroit(fonctionAgent.MonCompte_vehicule, fonctionAgent.vehicule)} label="Véhicule" />
            </Tabs>
            <TabPanel value={value} index={0} className={css.smallContainerAgent}>
                <div className="flex xs_directionColumn">
                    <div className="w60 mr5 xs_w100 ">
                        <h3 className="mb0 xs_textCenter xs_w90">Identification</h3>
                        <hr className=" xs_w90"></hr>
                        <div className="flex textRight alignICenter mb10px">
                            <label className="w25">Rôle</label>
                            <SelectSimple
                                disabled={disabledChamps}
                                tableau={listeGroupeRole}
                                indice={'Roles'}
                                classe={[css.nomargin, css.ml30px, css.w50]}
                                native={true}
                            ></SelectSimple>
                        </div>
                        <div className="textRight alignICenter flex mb10px">
                            <label className="w25">Actif</label>
                            <Checkbox
                                disabled={disabledChamps}
                                className={css.ml30px}
                                checked={checkBoxActif}
                                onChange={() => {
                                    setCheckBoxActif(!checkBoxActif);
                                }}
                            />
                        </div>
                        <div className="flex textRight alignICenter mb10px">
                            <label className="w25">Nom</label>
                            <Input
                                disabled={modification_onglet_general}
                                value={dataInput.nom}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    updateInput('nom', event.target.value.toUpperCase());
                                }}
                            />
                        </div>
                        <div className="flex textRight alignICenter mb10px">
                            <label className="w25">Prenom</label>
                            <Input
                                disabled={modification_onglet_general}
                                value={dataInput.prenom}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    updateInput('prenom', event.target.value);
                                }}
                            />
                        </div>
                        <div className="flex textRight alignICenter mb10px">
                            <label className="w25">Adresse</label>
                            <Input
                                disabled={modification_onglet_general}
                                value={dataInput.adresse1}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    updateInput('adresse1', event.target.value);
                                }}
                            />
                        </div>
                        <div className="flex textRight alignICenter mb10px">
                            <label className="w25">adresse</label>
                            <Input
                                disabled={modification_onglet_general}
                                value={dataInput.adresse2}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    updateInput('adresse2', event.target.value);
                                }}
                            />
                        </div>
                        <div className="flex textRight alignICenter mb10px">
                            <label className="w25">Code Postal</label>
                            <Input
                                disabled={modification_onglet_general}
                                value={dataInput.codePostal}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    updateInput('codePostal', event.target.value);
                                }}
                            />
                        </div>
                        <div className="flex textRight alignICenter mb10px">
                            <label className="w25">Ville</label>
                            <Input
                                disabled={modification_onglet_general}
                                value={dataInput.ville}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    updateInput('ville', event.target.value);
                                }}
                            />
                        </div>
                        <div className="flex textRight alignICenter mb10px">
                            <label className="w25">Email</label>
                            <Input
                                disabled={modification_onglet_general}
                                value={dataInput.email}
                                className={[css.nomargin, css.ml30px, css.w50]}
                                onChange={(event) => {
                                    updateInput('email', event.target.value);
                                }}
                            />
                        </div>
                        <div className="flex textRight alignICenter mb10px">
                            <label className="w25">Téléphone Fixe</label>
                            <TelephoneInput
                                style={{ textAlign: 'left', marginLeft: '30px', width: '50%' }}
                                disabled={modification_onglet_general}
                                value={dataInput.telephoneFixe}
                                onChange={(event) => {
                                    updateInput('telephoneFixe', event);
                                }}
                            />
                        </div>
                        <div className="flex textRight alignICenter mb10px">
                            <label className="w25">Téléphone mobile</label>
                            <TelephoneInput
                                style={{ textAlign: 'left', marginLeft: '30px', width: '50%' }}
                                disabled={modification_onglet_general}
                                value={dataInput.telephoneMobile}
                                onChange={(event) => {
                                    updateInput('telephoneMobile', event);
                                }}
                            />
                        </div>
                    </div>
                    <div className="mr5 w30 xs_w90">
                        {/* <h3 className="mb0 xs_textCenter">Résidence administrative </h3>
                        <hr className="xs_w90"></hr>
                        <div className="flex textRight alignICenter mb10px ml2">
                            <SelectSimple
                                disabled={roleAE}
                                tableau={site}
                                indice={'Site'}
                                classe={css.w100}
                                native={true}
                            ></SelectSimple>
                        </div> */}
                        <ViewAntenne data={antenne} />
                    </div>
                </div>
                <div className="mt2">
                    <BoutonSave />
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} className={css.smallContainerAgent}>
                <div className="flex textRight alignICenter mb10px xs_mt5">
                    <label className="w25 xs_w30">Mot de passe</label>
                    <Input
                        disabled={!verificationDroit(fonctionAgent.MonCompte_Modification_mot_de_passe, fonctionAgent.Modifcation_mot_de_passe)}
                        type="password"
                        placeholder="Password"
                        value={dataInput.password}
                        className={[css.nomargin, css.ml30px, css.w50]}
                        onChange={(event) => {
                            updateInput('password', event.target.value);
                        }}
                    />
                </div>
                <div className="flex textRight alignICenter mb10px">
                    <label className="w25 xs_w30">Mot de passe (confirmation)</label>
                    <Input
                        disabled={!verificationDroit(fonctionAgent.MonCompte_Modification_mot_de_passe, fonctionAgent.Modifcation_mot_de_passe)}
                        type="password"
                        placeholder="Password"
                        value={dataInput.confimationPassword}
                        className={[css.nomargin, css.ml30px, css.w50]}
                        onChange={(event) => {
                            updateInput('confimationPassword', event.target.value);
                        }}
                    />
                </div>
                {+id === user[usurpation].fk_id || testDroit(user[usurpation].roleFonction, fonctionAgent.Modifcation_mot_de_passe) ? (
                    <BoutonSave onglet="password" />
                ) : null}
            </TabPanel>
            <TabPanel value={value} index={2} className={css.smallContainerAgent}>
                <div className="flex textRight alignICenter mb10px xs_mt5">
                    <label className="w25">Marque</label>
                    <Input
                        disabled={disabledChamps}
                        value={dataInput.vehiculeMarque}
                        className={[css.nomargin, css.ml30px, css.w50]}
                        onChange={(event) => {
                            updateInput('vehiculeMarque', event.target.value);
                        }}
                    />
                </div>
                <div className="flex textRight alignICenter mb10px">
                    <label className="w25">Modéle</label>
                    <Input
                        disabled={disabledChamps}
                        value={dataInput.vehiculeModele}
                        className={[css.nomargin, css.ml30px, css.w50]}
                        onChange={(event) => {
                            updateInput('vehiculeModele', event.target.value);
                        }}
                    />
                </div>
                <div className="flex textRight alignICenter mb10px">
                    <label className="w25">Puissance</label>
                    <Input
                        disabled={disabledChamps}
                        value={dataInput.vehiculePuissance}
                        className={[css.nomargin, css.ml30px, css.w50]}
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={(event) => {
                            updateInput('vehiculePuissance', event.target.value);
                        }}
                    />
                </div>
                <div className="flex textRight alignICenter mb10px">
                    <label className="w25">Immaticulation</label>
                    <Input
                        disabled={disabledChamps}
                        value={dataInput.vehiculeImmatriculation}
                        className={[css.nomargin, css.ml30px, css.w50]}
                        onChange={(event) => {
                            updateInput('vehiculeImmatriculation', event.target.value);
                        }}
                    />
                </div>
                <div className="flex textRight alignICenter mb10px">
                    <label className="w25">Carburant</label>
                    <SelectSimple
                        disabled={disabledChamps}
                        tableau={carburant}
                        indice={'Carburant'}
                        classe={[css.nomargin, css.ml30px, css.w50]}
                    ></SelectSimple>
                </div>
                {verificationDroit(fonctionAgent.MonCompte_Modification_actif_role_véhicule, fonctionAgent.Modification_actif_role_vehicule) ? (
                    <BoutonSave onglet="vehicule" />
                ) : null}
            </TabPanel>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        removeFiltre() {
            dispatch({ type: 'removeFiltre' });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(FicheAgent);
