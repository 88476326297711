/* eslint-disable react-hooks/exhaustive-deps */
/**
 * liste information
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Paper, Table, TableBody, TableContainer, TableRow, TableCell, Dialog, DialogContent, DialogContentText, Button } from '@material-ui/core';

import { GET } from '../../components/Utils/requete';
import Container from '../../components/atoms/Container/container';
import PaginationTable from '../../components/organism/Table/Pagination';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import ListeButton from '../../components/atoms/ButtonCustom';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import CustomTooltips from '../../components/organism/Tooltip/customTooltip';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const ListeInformation = (props) => {
    const [dataInformation, setDataInformation] = useState([]);
    const [open, setOpen] = useState(false);
    const [modalText, setModalText] = useState('');
    const css = useStylesMaterielUIGlobal();
    const tableHead = [
        { id: 'date', label: 'date' },
        { id: 'nomPrenomUtilisateur', label: 'auteur' },
        { id: 'destinataires', label: 'destinataires' },
        { id: 'titre', label: 'titre' },
        { id: 'message', label: 'message' },
        { id: 'visible', label: 'visible' },
    ];

    async function recupDataInformation() {
        const response = await GET('/api/informations/list');
        if (response.success) setDataInformation(response.data);
    }

    function voirText(i) {
        const idx = dataInformation.findIndex((info) => info.idInformation === i);
        if (idx >= 0) {
            setModalText(dataInformation[idx].message);
            setOpen(true);
        }
    }

    useEffect(() => {
        recupDataInformation();
        props.addNomPage('Gestion informations');
    }, []);

    return (
        <div className="mr2 ml2">
            <h2>Messages à caractères informatifs </h2>
            <div className="flex flexEnd">
                <Link to={'/edition-information/0'}>
                    <ListeButton variant="outlined" size="small" onClick={() => props.removeFiltreMultiple()}>
                        Nouveau Message
                    </ListeButton>
                </Link>
            </div>
            <div>
                <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
                    <DialogContent>
                        <DialogContentText dangerouslySetInnerHTML={{ __html: modalText }}></DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
            <Container>
                <PaginationTable data={dataInformation} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHeadCustom data={tableHead}></TableHeadCustom>
                        <TableBody>
                            {setData(
                                props.recherche.fn,
                                dataInformation,
                                props.Page[0].page,
                                props.Page[0].rowsPerPage,
                                props.orderState,
                                props.orderByState,
                            ).map((row, index) => {
                                return (
                                    <TableRow className={css.trbody} key={index}>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Date</div>
                                            <CustomTooltips title="Éditer l'information">
                                                <Link to={`/edition-information/${row.idInformation}`}>{DateHeureFormat({ date: row.date })}</Link>
                                            </CustomTooltips>
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Auteur</div>
                                            {row.nomPrenomUtilisateur}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Destinataire</div>
                                            {row.destinataires}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Titre</div>
                                            {row.titre}
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Message</div>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                onClick={() => {
                                                    voirText(row.idInformation);
                                                }}
                                            >
                                                Voir le message
                                            </Button>
                                        </TableCell>
                                        <TableCell className={css.td} component="th" scope="row">
                                            <div>Visible</div>
                                            {row.visible === 0 ? 'non' : 'oui'}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationTable data={dataInformation} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        removeFiltreMultiple() {
            dispatch({ type: 'removeFiltreMultiple' });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ListeInformation);
