import { tranche } from './consts';

export default function calculTranche(quotient) {
    if (!quotient && quotient !== 0) {
        return 6;
    }
    for (let elem = 0; elem < tranche.length; elem++) {
        if (quotient >= tranche[elem].limiteBasse && quotient <= tranche[elem].limiteHaute) {
            return tranche[elem].tranche;
        }
    }
}
