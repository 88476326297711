/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import { IconButton, Typography, CardContent } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { GET, POST } from '../../components/Utils/requete';
import Paybox from '../../components/organism/paybox/paybox';
import CalendarCustom from '../../components/organism/Calendar/Calendar';
import CardComponent from '../../components/organism/card/card';
import ExcuseEleveEspaceResponsable from '../../components/organism/ExcuseEleve/excuseEleveEspaceResponsable';
import { color } from '../../components/Utils/consts';
import ModuleDeReinscription from './moduleDeReinscription';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const AcceuilResponsable = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const css = useStylesMaterielUIGlobal();
    const [dataInformation, setDataInformation] = useState([]);
    const usurpation = user.userC;
    const [paiementOuvert, setPaiemenOuvert] = useState(false);
    const [planningAtelier, setPlanningAtelier] = useState([]);
    const [contact, setContact] = useState([]);
    const [absencesEnseignant, setAbsencesEnseignant] = useState([]);
    const [reinscription, setReincription] = useState();
    const [inscription, setIncription] = useState(false);
    const [CGUInscription, setGCUInscription] = useState();
    const [reinscriptionData, setReinscriptionData] = useState([]);
    const date = new Date();
    const [display, setDisplay] = useState({
        information: 'none',
        contact: 'none',
        absences: 'none',
        absencesEleve: 'none',
        paybox: 'display',
        planning: 'none',
        inscription: 'display',
    });

    function datePeriode(jour) {
        const dateFormat = new Date(date.setDate(date.getDate() - date.getDay() + jour)).toLocaleDateString().replace(/\//g, '-').split('-').reverse();
        return dateFormat.join('-');
    }

    function Information(props) {
        const { information } = props;
        let header = '';
        return (
            <CardComponent>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        {information.titre}
                    </Typography>
                    {header}
                    <div
                        dangerouslySetInnerHTML={{
                            __html: information.message,
                        }}
                        className="leading100 "
                    ></div>
                </CardContent>
            </CardComponent>
        );
    }
    function changeDisplay(keys) {
        for (let index = 0; index < Object.keys(display).length; index++) {
            if (display[Object.keys(display)[index]] && Object.keys(display)[index] !== keys) {
                setDisplay((prevState) => ({
                    ...prevState,
                    [Object.keys(display)[index]]: 'none',
                }));
            }
            setDisplay((prevState) => ({
                ...prevState,
                [keys]: display[keys] === 'none' ? 'block' : 'none',
            }));
        }
    }
    function NomsEleves() {
        const eleves = [];
        for (let index = 0; index < planningAtelier.length; index++) {
            eleves.push(
                <span style={{ backgroundColor: `${planningAtelier[index].color}`, color: '#fff' }} className="ml1 fontSize0_7em p1 radius10">
                    {planningAtelier[index].prenomEleve}
                </span>,
            );
        }
        return <div className="flex">{eleves}</div>;
    }

    async function dataAccueil() {
        const response = await GET('/api/utilisateursResponsables/' + user[usurpation].fk_id + '/elevesAttached', undefined, {
            redirectOnError: false,
        });
        const tableauAtelierParEleve = [];
        const eviterDoublonsSite = [];
        const listeContact = [];
        const dataReinscription = [];
        for (let index = 0; index < response.data.length; index++) {
            const atelierParEleve = await POST(
                '/api/eleves/planning',
                {
                    fk_eleve: response.data[index].fk_eleve,
                    debutPeriode: `${user.libelleAnneeScolaire.split('-')[0]}-08-01`,
                    finPeriode: `${user.libelleAnneeScolaire.split('-')[1]}-07-30`,
                    fk_anneeScolaire: user.idAnneeScolaire,
                },
                { redirectOnError: false },
            );
            const scolariteInsterne = await GET(
                `/api/eleves/SI_In_JSON?fk_anneeScolaire=${user.idAnneeScolaire}&fk_eleve=${response.data[index].fk_eleve} `,
                undefined,
                { redirectOnError: false },
            );
            if (scolariteInsterne.data[0]) {
                if (
                    Math.abs(
                        new Date(new Date(new Date().setMonth(11)).setDate(31) - new Date(response.data[index].dateNaissance).getTime()).getFullYear() - 1970,
                    ) <= 25
                ) {
                    const listeSE = await GET('/api/eleves/' + response.data[index].fk_eleve + '/SE_list');
                    const parametreInscription = await GET('/api/parametres/list?categorie=inscription', undefined, {
                        redirectOnError: false,
                    });
                    let anneeInscriptions;
                    if (parametreInscription) {
                        let index = parametreInscription.data.findIndex((e) => e.cle === 'anneeInscriptions');
                        anneeInscriptions = parametreInscription.data[index].valeur;
                    }
                    const SEAnneeProchaine = listeSE.data.filter((e) => e.fk_anneeScolaire === +anneeInscriptions);
                    if (SEAnneeProchaine.length === 0) {
                        scolariteInsterne.data[0].ajout_SE = true;
                    }
                }
                dataReinscription.push(scolariteInsterne.data[0]);
                for (let index = 0; index < scolariteInsterne.data[0].scolariteInterne.length; index++) {
                    if (scolariteInsterne.data[0].scolariteInterne[index].reinscription) {
                        scolariteInsterne.data[0].scolariteInterne[index].color = '#FFBF80';
                    } else {
                        scolariteInsterne.data[0].scolariteInterne[index].color = '#fff';
                    }
                }
            }
            for (let elem = 0; elem < atelierParEleve.data.length; elem++) {
                if (atelierParEleve.data[elem].jour) {
                    const enseignantAbsent = [];
                    for (let i = 0; i < atelierParEleve.data[elem].absence.length; i++) {
                        if (atelierParEleve.data[elem].absence[i].absences) {
                            for (let e = 0; e < atelierParEleve.data[elem].absence[i].absences.length; e++) {
                                if (
                                    new Date(datePeriode(atelierParEleve.data[elem].jour)).setHours(0, 0, 0) >=
                                        new Date(atelierParEleve.data[elem].absence[i].absences[e].debut).setHours(0, 0, 0) &&
                                    new Date(datePeriode(atelierParEleve.data[elem].jour)).setHours(23, 59, 59) <=
                                        new Date(atelierParEleve.data[elem].absence[i].absences[e].fin).setHours(23, 59, 59)
                                ) {
                                    enseignantAbsent.push({
                                        libelleEnseignant: atelierParEleve.data[elem].absence[i].libelleEnseignant,
                                        libelleType: atelierParEleve.data[elem].libelleType,
                                    });
                                }
                            }
                            setAbsencesEnseignant(enseignantAbsent);
                            if (enseignantAbsent.length === atelierParEleve.data[elem].absence.length) {
                                atelierParEleve.data[elem].libelleType = 'Cours annulé';
                            }
                        }
                    }
                }
                if (!eviterDoublonsSite.includes(atelierParEleve.data[elem].libelleSite)) {
                    listeContact.push(atelierParEleve.data[elem].site);
                    eviterDoublonsSite.push(atelierParEleve.data[elem].site.libelleSite);
                }
            }

            tableauAtelierParEleve.push({
                prenomEleve: response.data[index].prenom,
                atelier: atelierParEleve.data,
                color: color[index],
                fk_eleve: response.data[index].fk_eleve,
            });
        }
        setReinscriptionData(dataReinscription);
        setContact(listeContact);
        setPlanningAtelier(tableauAtelierParEleve);
    }

    async function inscriptionOuverte() {
        if (!reinscription) {
            const response = await GET('/api/parametres/list?categorie=inscription', undefined, { redirectOnError: false });
            const filtreAnneeScolaire = await GET('/api/anneesScolaire/list');
            const date = new Date();
            if (response) {
                let index = response.data.findIndex((e) => e.cle === 'inscriptionsOuvertes');
                if (index >= 0) {
                    if (new Date(response.data[index].valeur) <= date) {
                        index = response.data.findIndex((e) => e.cle === 'anneeInscriptions');
                        response.data[index].libelleAnnee = filtreAnneeScolaire.data.filter(
                            (e) => e.idAnneeScolaire === +response.data[index].valeur,
                        )[0].libelleAnneeScolaire;
                        setReincription(response.data[index]);
                        index = response.data.findIndex((e) => e.cle === 'idCGUInscription');
                        if (index >= 0) {
                            const GCUInscription = await GET(`/api/informations/${response.data[index].valeur}`);
                            setGCUInscription(GCUInscription.data);
                        }
                    }
                }
                index = response.data.findIndex((e) => e.cle === 'inscriptionsPubliquesOuvertes');
                if (index >= 0) {
                    if (new Date(response.data[index].valeur) <= date) {
                        setIncription(true);
                    }
                }
            }
        }
    }
    async function getInformation() {
        const response = await GET(`/api/informations/list?lecture=true&visible=1`);
        const information = [];
        if (response.success) {
            for (let index = 0; index < response.data.length; index++) {
                if (response.data[index].destinataires && response.data[index].destinataires.includes('Responsable')) information.push(response.data[index]);
            }
            response.data.sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            });
            setDataInformation(response.data);
        }
        information.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });
        setDataInformation(information);
    }
    async function paiementOuverte() {
        const paiementDate = {
            cb1xFermeture: '',
            cbOuverture: '',
        };
        const response = await GET('/api/parametres/list?categorie=reglement', undefined, { redirectOnError: false });
        const index = ['cb1xFermeture', 'cbOuverture'];
        if (response) {
            for (let elem = 0; elem < index.length; elem++) {
                paiementDate[index[elem]] = response.data[response.data.findIndex((e) => e.cle === index[elem])].valeur;
            }
        }
        setPaiemenOuvert(
            new Date().getTime() > new Date(paiementDate.cbOuverture).getTime() && new Date().getTime() < new Date(paiementDate.cb1xFermeture).getTime(),
        );
    }
    useEffect(() => {
        getInformation();
        paiementOuverte();
        dataAccueil();
        inscriptionOuverte();
    }, []);
    if (window.matchMedia('(max-width: 576px)').matches) {
        return (
            <CardComponent className={[css.overflowAuto, css.m2, css.mt15, css.h80]} style={{ opacity: '0.9' }}>
                {paiementOuvert ? (
                    <div>
                        <div className="ml2 flex spaceBetween">
                            <h4>Paiement de la cotisation </h4>
                            <IconButton onClick={() => changeDisplay('paybox')}>{display.paybox === 'block' ? <RemoveIcon /> : <AddIcon />}</IconButton>
                        </div>
                        <div style={{ display: display.paybox }}>
                            <Paybox />
                        </div>
                    </div>
                ) : null}
                <div>
                    <div className="ml2 flex spaceBetween">
                        <h4>Planning </h4>
                        <IconButton onClick={() => changeDisplay('planning')}>{display.planning === 'block' ? <RemoveIcon /> : <AddIcon />}</IconButton>
                    </div>
                    <div style={{ display: display.planning }}>
                        <NomsEleves />
                        <CalendarCustom tableau={planningAtelier} className="mt2 " type="eleve" />
                    </div>
                </div>
                <div>
                    <div className="ml2 flex spaceBetween">
                        <h4>Les dernieres informations </h4>
                        <IconButton onClick={() => changeDisplay('information')}>{display.information === 'block' ? <RemoveIcon /> : <AddIcon />}</IconButton>
                    </div>
                    <div style={{ display: display.information }}>
                        {dataInformation.map((row, index) => {
                            return <Information information={row} key={index} />;
                        })}
                    </div>
                </div>
                <div>
                    <div className="ml2 flex spaceBetween">
                        <h4>Contact d'antenne </h4>
                        <IconButton onClick={() => changeDisplay('contact')}>{display.contact === 'block' ? <RemoveIcon /> : <AddIcon />}</IconButton>
                    </div>
                    <div style={{ display: display.contact === 'block' ? 'flex' : 'none' }} className="flex flexDRow">
                        {contact.map((e, key) => (
                            <div className="ml2 mr2">
                                <span>{`Ville: ${e.libelleSite}`}</span>
                                <br />
                                <span>{`Adresse: ${e.adresse1}`}</span>
                                <br />
                                <span>{`Code Postal: ${e.codePostal}`}</span>
                                <br />
                                <span>
                                    {`Mail: `} <a href={`mailto:${e.email}}`}>{e.email}</a>
                                </span>
                                <br />
                                <span>
                                    {`Téléphone: `}
                                    <a href={`tel:${e.telephone1}`}>{e.telephone1}</a>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                {absencesEnseignant.length === 0 ? null : (
                    <div>
                        <div className="ml2 flex spaceBetween">
                            <h4>Les absences des enseignants </h4>
                            <IconButton onClick={() => changeDisplay('absences')}>{display.absences === 'block' ? <RemoveIcon /> : <AddIcon />}</IconButton>
                        </div>
                        <div style={{ display: display.absences === 'block' ? 'flex' : 'none' }}>
                            {absencesEnseignant.map((e, key) => (
                                <div className="ml2 mr2 w50">
                                    <span>{`Enseignant: ${e.libelleEnseignant} Cours: ${e.libelleType}`}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {process.env.REACT_APP_ACCESS_MODULE.split(',').includes('presences') ? (
                    <div>
                        <div className="ml2 flex spaceBetween mb0">
                            <h4 className="mb0">Présence aux cours</h4>
                            <IconButton onClick={() => changeDisplay('absencesEleve')}>
                                {display.absencesEleve === 'block' ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        </div>
                        <div style={{ display: display.absencesEleve }}>
                            <p className="fontSize0_8em ml2">
                                Ce module vous permez de voir la presence ou non au cours, vous pouvez aussi informer le conservatoire du possible absence
                            </p>
                            <ExcuseEleveEspaceResponsable data={planningAtelier} />
                        </div>
                    </div>
                ) : null}
            </CardComponent>
        );
    }

    return (
        <div className="h80">
            <div className="flex ml7 mt5 mr7 h90 ">
                <CardComponent className={[css.w40]}>
                    <h3 className="textCenter">Planning des cours</h3>
                    <NomsEleves />
                    <CalendarCustom tableau={planningAtelier} className="mt2 h100" type="eleve" />
                </CardComponent>
                <CardComponent className={[css.w60, css.overflowAuto]} style={{ opacity: '0.9' }}>
                    {paiementOuvert ? (
                        <div>
                            <div className="ml2 flex spaceBetween">
                                <h4>Paiement de la cotisation </h4>
                                <IconButton onClick={() => changeDisplay('paybox')}>{display.paybox === 'block' ? <RemoveIcon /> : <AddIcon />}</IconButton>
                            </div>
                            <div style={{ display: display.paybox }}>
                                <Paybox />
                            </div>
                        </div>
                    ) : null}
                    {reinscription ? (
                        <div>
                            <div className="ml2 flex spaceBetween">
                                <h4>Ouverture des réinscriptions </h4>
                                <IconButton onClick={() => changeDisplay('inscription')}>
                                    {display.inscription === 'block' ? <RemoveIcon /> : <AddIcon />}
                                </IconButton>
                            </div>
                            <div style={{ display: display.inscription }}>
                                <ModuleDeReinscription
                                    reinscription={reinscription}
                                    inscription={inscription}
                                    CGUInscription={CGUInscription}
                                    reinscriptionData={reinscriptionData}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div>
                        <div className="ml2 flex spaceBetween">
                            <h4>Les dernières informations </h4>
                            <IconButton onClick={() => changeDisplay('information')}>
                                {display.information === 'block' ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        </div>
                        <div style={{ display: display.information }}>
                            {dataInformation.map((row, index) => {
                                return <Information information={row} key={index} />;
                            })}
                        </div>
                    </div>
                    <div>
                        <div className="ml2 flex spaceBetween">
                            <h4>Contact de votre antenne </h4>
                            <IconButton onClick={() => changeDisplay('contact')}>{display.contact === 'block' ? <RemoveIcon /> : <AddIcon />}</IconButton>
                        </div>
                        <div style={{ display: display.contact === 'block' ? 'flex' : 'none' }} className="flex flexDRow">
                            {contact.map((e, key) => (
                                <div className="ml2 mr2">
                                    <span>{`Ville: ${e.libelleSite}`}</span>
                                    <br />
                                    <span>{`Adresse: ${e.adresse1}`}</span>
                                    <br />
                                    <span>{`Code Postal: ${e.codePostal}`}</span>
                                    <br />
                                    <span>
                                        {`Mail: `} <a href={`mailto:${e.email}}`}>{e.email}</a>
                                    </span>
                                    <br />
                                    <span>{`Téléphone: ${e.telephone1}`}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    {absencesEnseignant.length === 0 ? null : (
                        <div>
                            <div className="ml2 flex spaceBetween">
                                <h4>Les absences des enseignants </h4>
                                <IconButton onClick={() => changeDisplay('absences')}>{display.absences === 'block' ? <RemoveIcon /> : <AddIcon />}</IconButton>
                            </div>
                            <div style={{ display: display.absences === 'block' ? 'flex' : 'none' }}>
                                <p className="fontSize0_8em ml2">
                                    Ce module vous permet de voir la présence ou non de l’élève à ses cours.
                                    <br /> Vous pouvez également informer le Conservatoire d’une absence.
                                </p>
                                {absencesEnseignant.map((e, key) => (
                                    <div className="ml2 mr2 w50">
                                        <span>{`Enseignant: ${e.libelleEnseignant} Cours: ${e.libelleType}`}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {process.env.REACT_APP_ACCESS_MODULE.split(',').includes('presences') ? (
                        <div>
                            <div className="ml2 flex spaceBetween mb0">
                                <h4 className="mb0">Présence aux cours</h4>
                                <IconButton onClick={() => changeDisplay('absencesEleve')}>
                                    {display.absencesEleve === 'block' ? <RemoveIcon /> : <AddIcon />}
                                </IconButton>
                            </div>
                            <div style={{ display: display.absencesEleve }}>
                                <p className="fontSize0_8em ml2">
                                    Ce module vous permez de voir la presence ou non au cours, vous pouvez aussi informer le conservatoire du possible absence
                                </p>
                                <ExcuseEleveEspaceResponsable data={planningAtelier} />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </CardComponent>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        dataCategorieEnfant(anneeScolaire) {
            dispatch({ type: 'nouvelInscription', anneeScolaire: anneeScolaire });
        },
    };
};

export default connect(null, mapDispatchToProps)(AcceuilResponsable);
