/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState } from 'react';

import { connect, useSelector } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';

import TabPanel from '../../../components/organism/Onglet/TabPanel';
import { testDroit } from '../../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';
import * as fonctionResponsable from '../../../fonctionsRoles/responsable';

import General from './General';
import Eleve from './Eleve';
import Cotisations from './Cotisations';

const FicheResponsable = (props) => {
    const css = useStylesMaterielUIGlobal();
    const [value, setValue] = useState(0);
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="ficheContainer">
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                style={{ position: 'fixed', width: 'calc(100% - 210px)', zIndex: '2', top: '64px' }}
            >
                <Tab className={css.fontSize12px} label="General" disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.general)} />
                <Tab className={css.fontSize12px} label="Élèves" disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.eleve)} />
                <Tab className={css.fontSize12px} disabled={!testDroit(user[usurpation].roleFonction, fonctionResponsable.cotisation)} label="Cotisations" />
            </Tabs>
            <TabPanel value={value} index={0} className={css.smallContainer}>
                <General />
            </TabPanel>
            <TabPanel value={value} index={1} className={css.smallContainer}>
                <Eleve />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Cotisations />
            </TabPanel>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
    };
}

export default connect(null, mapDispatchToProps)(FicheResponsable);
