import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const SelectSearch = (props) => {
    const keysTableau = props.tableau[0];
    let idName = '';
    let libName = '';
    if (keysTableau !== undefined) {
        idName = Object.keys(keysTableau)[0];
        libName = Object.keys(keysTableau)[1];
    }
    function updateFiltre(event, keys) {
        let objectValue = event;
        if (objectValue === null) {
            objectValue = '';
        }
        props.filtre([{ [keys]: objectValue[idName] }]);
    }
    return (
        <div>
            {/*             <p>{props.tableau[value][libName]}</p> */}
            <Autocomplete
                onChange={(event, newValue) => {
                    updateFiltre(newValue, props.indice);
                }}
                className={props.classe}
                options={props.tableau}
                getOptionLabel={(option) => option[libName]}
                renderInput={(params) => <TextField {...params} label={props.label} />}
            />
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        // eslint-disable-next-line func-names
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
    };
}
export default connect(null, mapDispatchToProps)(SelectSearch);
