import React from 'react';
import { makeStyles, Slider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

const useStyles = makeStyles({
    root: {
        width: 300,
    },
});
const Slide = (props) => {
    const classes = useStyles();
    const marks = [];
    for (let elem = 0; elem <= props.note; elem++) {
        marks.push({
            value: elem,
            label: elem,
        });
    }
    return (
        <div className={classes.root}>
            <Typography gutterBottom>{props.lib}</Typography>
            <Slider
                defaultValue={props.value}
                step={1}
                marks
                min={0}
                max={5}
                valueLabelDisplay="off"
                // eslint-disable-next-line react/jsx-no-duplicate-props
                marks={marks}
                onChangeCommitted={(event, value) => props.addNote(value, props.index)}
                disabled={props.disabled}
            />
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNote(note, index) {
            dispatch({ type: 'updateNote', note, index });
        },
    };
}
export default connect(null, mapDispatchToProps)(Slide);
