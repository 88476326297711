import token from '../../reducer/token';

const defaultParams = {
    download: false,
    upload: false,
    redirectOnError: true,
};

const Requete = (method) => {
    const infoUser = token.getState();
    return async (url, data, params) => {
        if (params) {
            params = { ...defaultParams, ...params };
        } else {
            params = { ...defaultParams };
        }
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${params.token ? params.token : infoUser.token}`);
        headers.append('id', params.idUtilisateur ? params.idUtilisateur : infoUser.user.idUtilisateur);
        let body = data;
        if (!params.upload) {
            headers.append('Content-Type', 'application/json');
            body = JSON.stringify(data);
        }
        const options = {
            headers,
            method,
            body,
        };
        const request = await fetch(url, options);
        if (params.download && request.status === 200) {
            return request.blob();
        }
        const response = await request.json();
        response.status = request.status;
        if (response.status === 200 || !params.redirectOnError) {
            return response;
        } else {
            window.location.pathname = `/erreur/${response.status}`;
        }
    };
};

export const GET = Requete('GET');
export const POST = Requete('POST');
export const DELETE = Requete('DELETE');
