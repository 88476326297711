/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { connect, useSelector } from 'react-redux';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Checkbox, Button, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import NoteAdd from '@material-ui/icons/NoteAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { GET, POST, DELETE } from '../../../components/Utils/requete';
import Input from '../../../components/atoms/TextField';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import SelectSimple from '../../../components/organism/SelectSimple/SelectSimple';
import euroFormate from '../../../components/Utils/euroFormate';
import downloadFile from '../../../components/Utils/downloadFile';
import DateHeureFormat from '../../../components/Utils/DateHeureFormat';
import CalculCotisation from '../../../components/organism/CalculCotisation/calculCotisation';
import calculTranche from '../../../components/Utils/calculTranche';
import calculQuotient from '../../../components/Utils/calculQuotient';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';
import { testDroit } from '../../../components/Utils/testDroit';
import ListeButton from '../../../components/atoms/ButtonCustom';

import { tableHeadFiscalite, tableHeadCotisation, tableHeadCotisationDetail } from '../const';
import * as fonctionResponsable from '../../../fonctionsRoles/responsable';

const Cotisations = (props) => {
    let { id } = useParams();
    const css = useStylesMaterielUIGlobal();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [open, setOpen] = useState(false);
    const reducerFiltre = useSelector((state) => state.SelectSimple);
    const [dataFiscalite, setDataFiscalite] = useState([]);
    const [dataCotisation, setDataCotisation] = useState([]);
    const [anneeScolaire, setAnneeScolaire] = useState([]);
    const [abs, setAbs] = useState(false);
    const [idFiscaliteUpdate, setIdFiscaliteUpdate] = useState(0);
    const [dataNewFiscalite, setDataNewFiscalite] = useState({
        income: '',
        parts: '',
        quotient: '',
        tranche: '',
        fileNameReal: '',
        localFileName: '',
    });
    const [nameFile, setNameFile] = useState();
    const [idDeleteFiscalite, setIdDeleteFiscalite] = useState();
    const [uploadFileData, setUploadFileData] = useState();
    const [modificationRevenue, setModificationRevenue] = useState();

    function updateState(keys, valueI) {
        setDataNewFiscalite((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    function libelleAnneeScolaire(id) {
        const libelle = anneeScolaire.filter((e) => +e.idAnneeScolaire === +id);
        return libelle[0].libelleAnneeScolaire;
    }
    function Modal(props) {
        let content = props.content;
        switch (props.content) {
            case 'ajoutFiscalite':
                content = (
                    <>
                        {modificationRevenue ? (
                            <div className="textRight flex mb10px mt10px">
                                <label className="w25 textRight">Année Scolaire</label>
                                <label className="w50 mt0 textLeft ml2em">{libelleAnneeScolaire(modificationRevenue)}</label>
                            </div>
                        ) : (
                            <SelectSimple tableau={anneeScolaire} indice={'AnneeScolaire'} inputLabel={'Année Scolaire'} classe={[css.w80]}></SelectSimple>
                        )}

                        <div className="textRight flex mb10px mt10px">
                            <label className="w25 textRight">Revenu fiscal de référence</label>
                            <Input
                                disabled={abs}
                                value={abs === true ? 0 : dataNewFiscalite.income}
                                className={[css.w50, css.ml2em, css.mt0]}
                                onChange={(event) => {
                                    updateState('income', event.target.value);
                                }}
                            />
                        </div>
                        <div className="textRight flex mb10px">
                            <label className="w25 textRight">Nombre de part</label>
                            <Input
                                disabled={abs}
                                value={abs === true ? 0 : dataNewFiscalite.parts}
                                className={[css.w50, css.ml2em, css.mt0]}
                                onChange={(event) => {
                                    updateState('parts', event.target.value);
                                }}
                            />
                        </div>
                        <div className="textRight flex mb10px">
                            <label className="w25 textRight">Quotient familial</label>
                            <Input
                                disabled={true}
                                value={abs === true ? 0 : calculQuotient(dataNewFiscalite.income, dataNewFiscalite.parts)}
                                className={[css.w50, css.ml2em, css.mt0]}
                            />
                        </div>
                        <div>
                            <Checkbox
                                checked={abs}
                                onChange={() => {
                                    setAbs(!abs);
                                }}
                            />
                            <span>Je choisis de ne pas transmettre mon avis d'imposition</span>
                        </div>
                        <div className="textRight flex mb10px">
                            <label>Avis d'imposition</label>
                            <input
                                id="raised-button-file"
                                type="file"
                                hidden
                                onChange={(event) => {
                                    uploadFile(event.target.files[0]);
                                }}
                            />
                            <div className="flex directionColumn ">
                                <label htmlFor="raised-button-file" className="flex">
                                    <Button startIcon={<NoteAdd />} className={[css.ml2em, css.mt0]} variant="contained" color="default" component="span">
                                        Import
                                    </Button>
                                </label>
                                <label className="mt5 cVert32CD32">{nameFile}</label>
                            </div>
                        </div>
                        <div className="mt5">
                            <Button size="small" variant="outlined" color="secondary" onClick={() => setOpen(false)}>
                                Annuler
                            </Button>
                            <Button size="small" variant="outlined" onClick={() => ajoutFiscalite()} className={css.ml2_5em}>
                                Valider
                            </Button>
                        </div>
                    </>
                );
                break;
            case 'deleteFiscalite':
                content = (
                    <div className="flex directionColumn">
                        <span>Voulez-vous supprimer cette fiscalité</span>
                        <div className="mt5 w100">
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                    setOpen(false);
                                    setIdDeleteFiscalite();
                                }}
                                color="secondary"
                                className={css.mr2}
                            >
                                Retour
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                    deleteFiscalite();
                                }}
                            >
                                Supprimer fiscalité
                            </Button>
                        </div>
                    </div>
                );
                break;
            default:
                break;
        }
        return (
            <DialogCustom
                open={open}
                onClose={() => {
                    setOpen(false);
                    setIdDeleteFiscalite();
                    clearModalData();
                }}
                children={content}
                maxWidth="lg"
                fullScreen={props.fullScreen}
            />
        );
    }
    function TableauCotisation(props) {
        if (!props.tableau) {
            return <></>;
        }
        function returText(index, element) {
            switch (index) {
                case 1:
                    return euroFormate(element);
                case 2:
                    return DateHeureFormat({ date: element });
                default:
                    return element;
            }
        }
        return (
            <Table className={css.BcGrisClair}>
                <TableHeadCustom data={tableHeadCotisationDetail}></TableHeadCustom>
                <TableBody>
                    {props.tableau.split('|-|').map((elem, index) => (
                        <TableRow key={index}>
                            {elem.split('|').map((e, i) =>
                                ![0, 3].includes(i) ? (
                                    <TableCell key={i} component="th" scope="row" className={css.p0}>
                                        <h5 className="ml5">{returText(i, e)}</h5>
                                    </TableCell>
                                ) : null,
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }
    function clearModalData() {
        setIdFiscaliteUpdate(0);
        setAbs(false);
        for (let index = 0; index < Object.keys(dataNewFiscalite).length; index++) {
            dataNewFiscalite[Object.keys(dataNewFiscalite)[index]] = '';
        }
        props.removeFiltre();
        setModificationRevenue();
    }

    async function uploadFile(file) {
        if (file.size > 2097152) {
            alert('Votre fichier est trop gros, il ne doit pas dépasser 2Mo');
            setNameFile();
            setUploadFileData();
            return;
        }
        setNameFile(file.name);
        const data = new FormData();
        data.delete('file');
        data.delete('fk_responsable');
        data.delete('fk_anneeScolaire');
        data.append('file', file);
        data.append('fk_responsable', id);
        data.append('fk_anneeScolaire', reducerFiltre.AnneeScolaire);
        setUploadFileData(data);
    }
    async function recupFiltre() {
        const anneeScolaireFiltre = await GET('/api/anneesScolaire/list');
        setAnneeScolaire(anneeScolaireFiltre.data);
    }
    async function recupDataFiscalite() {
        const response = await GET('/api/responsablesRevenus/listFiscalite/' + id);
        setDataFiscalite(response.data);
    }
    async function editFiscalite(row) {
        setOpen(true);
        props.filtre([{ AnneeScolaire: row.fk_anneeScolaire }]);
        setModificationRevenue(row.fk_anneeScolaire);
        setIdFiscaliteUpdate(row.idRevenu);
        setDataNewFiscalite((prevState) => ({
            ...prevState,
            income: row.revenuFiscal,
            parts: row.nombrePart,
        }));
    }
    async function ajoutFiscalite() {
        const data = {
            idRevenu: idFiscaliteUpdate,
            fk_responsable: id,
            fk_anneeScolaire: reducerFiltre.AnneeScolaire,
            revenuFiscal: abs === true ? 0 : dataNewFiscalite.income,
            nombrePart: abs === true ? 0 : dataNewFiscalite.parts,
            quotient: calculQuotient(dataNewFiscalite.income, dataNewFiscalite.parts),
            exoneration: 0,
            absence: abs === true ? 1 : 0,
        };
        for (let index = 0; index < Object.keys(data).length; index++) {
            if (Object.values(data)[index] === '') {
                return props.notification({
                    message: 'Veuillez remplir tous les champs',
                });
            }
        }
        const response = await POST('/api/responsablesRevenus/' + idFiscaliteUpdate, data, {
            redirectOnError: false,
        });
        if (response.success === false) {
            props.notification({
                message: response.message,
            });
        } else {
            if (uploadFileData) {
                const response = await POST('/api/fichiers/upload/responsablesRevenus', uploadFileData, {
                    upload: true,
                    redirectOnError: false,
                });
                if (response.success) {
                    setOpen(false);
                } else {
                    alert(response.message);
                }
            } else {
                setOpen(false);
            }
            clearModalData();
            recupDataFiscalite();
        }
    }
    async function deleteFiscalite() {
        await DELETE('/api/responsablesRevenus/' + idDeleteFiscalite);
        recupDataFiscalite();
        setIdDeleteFiscalite();
        setOpen(false);
    }
    async function recupDataCotisation() {
        const response = await GET('/api/cotisations/liste/' + id);
        setDataCotisation(response.data);
    }
    async function reactivationPaiement() {
        const cotisationAnneeEnCour = dataCotisation.filter((e) => e.fk_anneeScolaire === user.idAnneeScolaire);
        const reglements = cotisationAnneeEnCour[0].concatCotisation.split('|-|');
        for (let index = 0; index < reglements.length; index++) {
            const reglement = reglements[index].split('|');
            const data = {
                idReglement: reglement[0],
                fk_cotisation: cotisationAnneeEnCour[0].idCotisation,
                montant: reglement[1],
                fk_mode: reglement[6],
                date: reglement[2],
                valide: 0,
                quittance: reglement[4],
                abonnement: reglement[7] ? reglement[7] : null,
            };
            const response = await POST(`/api/reglements/${cotisationAnneeEnCour[0].idCotisation}`, data, {
                redirectOnError: false,
            });
            if (response.success) {
                props.notification({ message: 'Paiement réactivé', status: true });
            }
        }
    }

    useEffect(() => {
        recupFiltre();
    }, [reducerFiltre]);
    useEffect(() => {
        if (id !== '0') {
            recupDataFiscalite();
            recupDataCotisation();
        }
        props.filtre([{ AnneeScolaire: user.idAnneeScolaire }]);
    }, []);
    return (
        <div className="ficheContainer mt5">
            {window.matchMedia('(max-width: 576px)').matches ? (
                <span>Vous ne pouvez pas voir cet onglet sur mobile</span>
            ) : (
                <>
                    {Modal({ content: idDeleteFiscalite ? 'deleteFiscalite' : 'ajoutFiscalite' })}
                    <div>
                        <h2>Fiscalité</h2>
                        {testDroit(user[usurpation].roleFonction, fonctionResponsable.Ajouter_fiscalite) ? (
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setOpen(true);
                                }}
                                className={[css.mb2, css.floatRight]}
                            >
                                Ajouter
                            </Button>
                        ) : null}
                    </div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHeadCustom data={tableHeadFiscalite}></TableHeadCustom>
                            <TableBody>
                                {dataFiscalite.map((row, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row.libelleAnneeScolaire}
                                                {testDroit(user[usurpation].roleFonction, fonctionResponsable.reactivation_paiement) ? (
                                                    <IconButton
                                                        className={css.p2px}
                                                        color="secondary"
                                                        onClick={() => {
                                                            setIdDeleteFiscalite(row.idRevenu);
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                ) : null}
                                                {testDroit(user[usurpation].roleFonction, fonctionResponsable.Editer_fiscalite) ? (
                                                    <IconButton
                                                        className={css.p2px}
                                                        onClick={() => {
                                                            editFiscalite(row);
                                                        }}
                                                        color="primary"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                ) : null}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {euroFormate(row.revenuFiscal)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.nombrePart}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.quotient}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {!row.revenuFiscal ? row.nombresTranches : calculTranche(calculQuotient(row.revenuFiscal, row.nombrePart))}
                                            </TableCell>
                                            <TableCell className="textCenter" component="th" scope="row">
                                                <Link
                                                    onClick={() => {
                                                        downloadFile(
                                                            'responsablesRevenus',
                                                            {
                                                                fk_responsable: id,
                                                                fk_anneeScolaire: row.fk_anneeScolaire,
                                                            },
                                                            row.fileName,
                                                        );
                                                    }}
                                                >
                                                    {row.fileName}
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <h2 className="mb0">Cotisations</h2>

                    {testDroit(user[usurpation].roleFonction, fonctionResponsable.Calcul_cotisation) ? (
                        <ListeButton variant="outlined" size="small" color="primary" className="mb2 floatRight" onClick={() => reactivationPaiement()}>
                            Réactiver le paiement
                        </ListeButton>
                    ) : null}
                    {testDroit(user[usurpation].roleFonction, fonctionResponsable.Calcul_cotisation) ? (
                        <CalculCotisation className="mb2 floatRight" idFonction={110} responsable={id} callback={recupDataCotisation} />
                    ) : null}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHeadCustom data={tableHeadCotisation}></TableHeadCustom>
                            <TableBody>
                                {dataCotisation.map((row, index) => {
                                    return (
                                        <TableRow className={[css.alignTop, css.textLeft]} key={index}>
                                            <TableCell component="th" scope="row">
                                                <table className="w80 mb10px">
                                                    <tbody>
                                                        <tr className="fontBold">
                                                            <td>{row.libelleAnneeScolaire}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Montant total</td>
                                                            <td className="floatRight">{euroFormate(row.montantTotal)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Montant déjà réglé</td>
                                                            <td className="floatRight">{euroFormate(row.MontantDejaRegle)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Reste à régler</td>
                                                            <td className="floatRight">{euroFormate(row.montantTotal - row.MontantDejaRegle)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <TableauCotisation tableau={row.concatCotisation} />
                                            </TableCell>
                                            <TableCell
                                                dangerouslySetInnerHTML={{
                                                    __html: row.explication,
                                                }}
                                            ></TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        removeFiltre() {
            dispatch({ type: 'removeFiltre' });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(Cotisations);
