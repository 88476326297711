/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Pageview from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { DELETE, GET, POST } from '../../../components/Utils/requete';
import TableHeadCustom from '../../../components/organism/Table/TableHead';
import SelectSimple from '../../../components/organism/SelectSimple/SelectSimple';
import SelectSearch from '../../../components/organism/SelectSearch/SelectSearch';
import ExportExcel from '../../../components/organism/ExportTableau/exportExcel';
import DialogCustom from '../../../components/organism/Dialog/dialog';
import creationTableauFiltre from '../../../components/Utils/createTableauFiltre';
import { suppressionDoublon } from '../../../components/Utils/suppressionDoublon';
import Contact from '../../../components/organism/Contact/Contact';
import ExcuseEleveAtelier from '../../../components/organism/ExcuseEleve/excuseEleveAtelier';
import { testDroit } from '../../../components/Utils/testDroit';
import { ButtonCustom } from './Fiche';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

import { tableHeadEleveF, tableHeadInfoEleveF, tableHeadInfoEleveExport, selectDialogAddEleve } from '../consts';
import * as fonctionAtelier from '../../../fonctionsRoles/atelier';

const AtelierEleves = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const valueFiltre = useSelector((state) => state.SelectSimple);
    const css = useStylesMaterielUIGlobal();
    const [tableauFiltre, setTableauFiltre] = useState({
        Statut: [],
        Instrument: [],
        Eleve: [],
        ScolariteInterne: [],
        NomEleve: '',
    });
    const [dataEleve, setDataEleve] = useState([]);
    const [open, setOpen] = useState({ open: false });
    const [mail, setMail] = useState([]);

    function Modal() {
        let content;
        let fullScreen = false;
        switch (open.event) {
            case 'supprEleve':
                content = (
                    <>
                        <span className="mr2 textCenter w100 block">Voulez-vous supprimer cet élève</span>
                        <div className="mt2 textCenter">
                            <ButtonCustom
                                type="bouton"
                                label="Non"
                                onClick={() => setOpen({ open: false })}
                                color="secondary"
                                className={[css.mr20, css.mt2]}
                            />
                            <ButtonCustom
                                type="bouton"
                                label="Oui"
                                onClick={() => {
                                    suppressionEleve(open.params);
                                }}
                                suppr="supprEnseignant"
                            />
                        </div>
                    </>
                );
                break;
            case 'updateEleve':
            case 'addEleve':
                content = (
                    <>
                        {open.event === 'addEleve' && open.event !== 'updateEleve' ? (
                            <>
                                <h3>Ajouter un élève</h3>
                                <SelectSearch
                                    tableau={tableauFiltre.Eleve}
                                    label={'Eleve'}
                                    indice={'Eleve'}
                                    classe={[css.nomargin, css.ml30px, css.w80]}
                                ></SelectSearch>
                            </>
                        ) : open.event === 'updateEleve' ? (
                            <h3>Modifier les informations de {tableauFiltre.NomEleve} </h3>
                        ) : null}
                        {selectDialogAddEleve.map((row) => (
                            <SelectSimple
                                tableau={tableauFiltre[row.indice]}
                                inputLabel={row.label}
                                indice={row.indice}
                                classe={[css.nomargin, css.ml30px, css.w80]}
                            ></SelectSimple>
                        ))}
                        <div className="mt10 textCenter mb2">
                            <ButtonCustom type="bouton" label="Valider" onClick={() => associerEleve()} className={css.mr20} />
                            <ButtonCustom type="bouton" label="Retour" onClick={() => setOpen({ open: false })} color="secondary" />
                        </div>
                    </>
                );
                break;
            case 'infoEleve':
                fullScreen = true;
                content = (
                    <>
                        <ButtonCustom type="bouton" label="Retour" onClick={() => setOpen({ open: false })} color="secondary" />
                        <h3>Détail des élèves de l'atelier</h3>
                        <div className="flex alignICenter mb2">
                            <div className="flex w100 flexEnd">
                                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Export_excel) ? (
                                    <ExportExcel data={dataEleve} head={tableHeadInfoEleveExport} nomFichier="Liste élèves" />
                                ) : null}
                                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Envoi_email) ? (
                                    <div>
                                        <ButtonCustom type="bouton" label={<Contact email={mail} lib="Email à tous" />} />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeadCustom data={tableHeadInfoEleveF}></TableHeadCustom>
                                <TableBody>
                                    {dataEleve.map((row, index) => {
                                        return (
                                            <TableRow className={css.trbody} key={index}>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Elève</div>
                                                    {row.nomPrenomEleve}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Statut</div>
                                                    {row.libelleStatut}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Mobile</div>
                                                    {suppressionDoublon(`${row.concatTelephoneMobile},${row.concatTelephoneFixe}`).map((e) => (
                                                        <>
                                                            <Contact telephone={e} />
                                                        </>
                                                    ))}
                                                </TableCell>
                                                <TableCell className={css.td} component="th" scope="row">
                                                    <div>Email</div>
                                                    {suppressionDoublon(row.concatEmails).map((e) => (
                                                        <Contact email={e} />
                                                    ))}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                );
                break;
            default:
                break;
        }
        return (
            <DialogCustom
                open={open.open}
                onClose={() => {
                    setOpen({ open: false, content: '' });
                }}
                children={content}
                fullScreen={fullScreen}
            />
        );
    }
    function updateState(keys, valueI) {
        setTableauFiltre((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }

    async function ElevesAtelier() {
        const responseEleve = await POST('/api/ateliers/elevesByAteliers', {
            fk_atelier: props.id,
        });
        let tableauMail = [];
        for (let elem = 0; elem < responseEleve.data.length; elem++) {
            tableauMail.push(responseEleve.data[elem].concatEmails);
        }
        setMail(tableauMail);
        setDataEleve(responseEleve.data);
    }

    async function associerEleve() {
        const data = {
            fk_eleve: valueFiltre.Eleve,
            fk_atelier: props.id,
            fk_instrument: valueFiltre.Instrument,
            fk_statut: valueFiltre.Statut,
            fk_scolariteInterne: valueFiltre.ScolariteInterne,
        };
        if (valueFiltre.Instrument === '' || valueFiltre.Statut === '' || data.fk_scolariteInterne === '') {
            return props.notification({ message: 'Tous les champs doivent étre rempli' });
        } else {
            await POST('/api/ateliers/attachedEleve', data);
            setOpen({ open: false });
        }

        return ElevesAtelier();
    }
    async function suppressionEleve(idUser) {
        const data = {
            fk_eleve: idUser,
            fk_atelier: props.id,
        };
        await DELETE('/api/ateliers/attachedEleve', data);
        setOpen({ open: false });
        ElevesAtelier();

        setOpen({ open: false });
        return ElevesAtelier();
    }
    async function filtreAddEleve() {
        if (tableauFiltre.Statut.length === 0) {
            const requete = await GET('/api/ateliers/ateliersStatut');
            updateState('Statut', requete.data);
        }
        let dataRequete;
        const dataEleve = {
            fk_anneeScolaire: valueFiltre.AnneeScolaire,
            fk_atelierType: valueFiltre.SousGroupeAtelier,
        };
        const eleveFiltre = await POST('/api/eleves/listElevesFromAnneeScolaireAndAtelierType/', dataEleve);
        updateState('Eleve', eleveFiltre.data);
        const dataInstrument = {
            fk_anneeScolaire: valueFiltre.AnneeScolaire,
            fk_site: [valueFiltre.Site],
        };
        const instrumentFiltre = await POST('/api/cotisations/instruments', dataInstrument);
        updateState('Instrument', instrumentFiltre.data);
        if (valueFiltre.Eleve !== '') {
            const response = await GET(`/api/eleves/${valueFiltre.Eleve}/SI_list?fk_anneeScolaire=${valueFiltre.AnneeScolaire}`);
            updateState('ScolariteInterne', creationTableauFiltre(response.data, 'idScolarite', 'libelleOffre'));
            dataRequete = response.data;
            if (response.data.length < 2) {
                props.filtre([{ ScolariteInterne: response.data[0].idScolarite }]);
            }
        }
        if (valueFiltre.ScolariteInterne) {
            const infoFiltre = dataRequete.filter((e) => e.idScolarite === valueFiltre.ScolariteInterne);
            props.filtre([{ Instrument: valueFiltre.Instrument ? valueFiltre.Instrument : infoFiltre[0].fk_instrument }, { Statut: infoFiltre[0].fk_statut }]);
        }
    }
    useEffect(() => {
        ElevesAtelier();
    }, []);
    useEffect(() => {
        filtreAddEleve();
    }, [valueFiltre.Eleve, valueFiltre.ScolariteInterne]);

    return (
        <div>
            {Modal()}
            <div className="flex mt5">
                <h3 className="w100 nomargin">Élèves </h3>
                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Information_eleves) ? (
                    <ButtonCustom
                        type="icone"
                        label={<Pageview />}
                        onClick={() => {
                            setOpen({ open: true, event: 'infoEleve' });
                        }}
                        className={[css.floatRight, css.p0, css.cVert]}
                    />
                ) : null}
                {testDroit(user[usurpation].roleFonction, fonctionAtelier.Associer_eleve) ? (
                    <ButtonCustom
                        disabled={+props.id === 0}
                        type="icone"
                        label={<AddBoxIcon />}
                        onClick={() => {
                            props.filtre([{ Eleve: '' }, { ScolariteInterne: '' }, { Instrument: '' }, { Statut: '' }]);
                            filtreAddEleve();
                            setOpen({ open: true, event: 'addEleve' });
                        }}
                        className={[css.floatRight, css.p0, css.cVert, css.xs_mr5]}
                    />
                ) : null}
            </div>
            {+props.maximumEleves > 0 ? <p className="nomargin fontSize0_9em">nombre d'élève {`${dataEleve.length}/${props.maximumEleves}`}</p> : null}
            <hr></hr>
            <TableContainer component={Paper}>
                <Table>
                    <TableHeadCustom data={tableHeadEleveF}></TableHeadCustom>
                    <TableBody>
                        {dataEleve.map((row, index) => {
                            const button = (
                                <>
                                    {testDroit(user[usurpation].roleFonction, fonctionAtelier.Desassocier_eleve) ? (
                                        <ButtonCustom
                                            type="icone"
                                            label={<DeleteForeverIcon />}
                                            onClick={() => {
                                                setOpen({ open: true, event: 'supprEleve', params: row.fk_eleve });
                                            }}
                                            className={[css.floatRight, css.p0]}
                                            color="secondary"
                                        />
                                    ) : null}
                                    {testDroit(user[usurpation].roleFonction, fonctionAtelier.Modifier_eleve) ? (
                                        <ButtonCustom
                                            type="icone"
                                            label={<EditIcon />}
                                            onClick={() => {
                                                filtreAddEleve();
                                                updateState('NomEleve', row.nomPrenomEleve);
                                                props.filtre([
                                                    { Eleve: row.fk_eleve },
                                                    {
                                                        ScolariteInterne: row.fk_scolariteInterne,
                                                    },
                                                    {
                                                        Instrument: row.fk_instrument,
                                                    },
                                                    { Statut: row.fk_statut },
                                                ]);
                                                setOpen({
                                                    open: true,
                                                    event: 'updateEleve',
                                                });
                                            }}
                                            className={[css.floatRight, css.p0]}
                                        />
                                    ) : null}
                                    {testDroit(user[usurpation].roleFonction, fonctionAtelier.Presences_élève) ? (
                                        props.dataAtelier.jour ? (
                                            <ExcuseEleveAtelier dataAtelier={props.dataAtelier} idEleve={row.fk_eleve} nomEleve={row.nomPrenomEleve} />
                                        ) : null
                                    ) : null}
                                </>
                            );
                            return (
                                <TableRow className={css.trbody} key={index}>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>
                                            Elève
                                            {window.matchMedia('(max-width: 576px)').matches ? button : null}
                                        </div>
                                        {window.matchMedia('(min-width: 576px)').matches ? button : null}
                                        {testDroit(user[usurpation].roleFonction, fonctionAtelier.Lien_le_profil_eleve) ? (
                                            <Link to={`/fiche-eleve/${row.fk_eleve}`}>{row.nomPrenomEleve}</Link>
                                        ) : (
                                            row.nomPrenomEleve
                                        )}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        {row.libelleInstrument ? <div>Instrument</div> : null}
                                        {row.libelleInstrument}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Statut</div>
                                        {row.libelleStatut}
                                    </TableCell>
                                    <TableCell className={css.td} component="th" scope="row">
                                        <div>Date Creation</div>
                                        {new Date(row.dateCreation).toLocaleString('fr-FR')}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(AtelierEleves);
