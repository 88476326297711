/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { TextareaAutosize } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import ButtonLivret from '../ButtonLivret';
import Input from '../../../components/atoms/TextField';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';

const ModuleDisciplineCreate = (props) => {
    const css = useStylesMaterielUIGlobal();
    const [addInput, setAddInput] = useState([]);
    const idModule = useSelector((state) => state.EditModuleLivret);
    const [dataInput, setDataInput] = useState({
        Titre: '',
        Critere: '',
    });
    const [idCritere, setIdCritere] = useState();
    const [reloadView, setReloadView] = useState(false);
    function update(keys, valueI) {
        setDataInput((prevState) => ({
            ...prevState,
            [keys]: valueI,
        }));
    }
    function deleteChamps(data, idChamps) {
        const newTableauCritere = data.filter((e) => e.idDiscipline !== idChamps);
        for (let index = 0; index < newTableauCritere.length; index++) {
            newTableauCritere[index].idDiscipline = index + 1;
        }
        addElement(newTableauCritere);
    }
    function addElement(dataEditModule) {
        const variableTitre = `Titre${addInput.length}`;
        const variableDescription = `Description${addInput.length}`;
        let element = [];
        if (dataEditModule) {
            for (let index = 0; index < dataEditModule.length; index++) {
                const titre = `Titre${index}`;
                const description = `Description${addInput.length}`;
                element.push(
                    <div className="flex mt1 ">
                        <div className="flex mt1 w100">
                            <div className="flex alignICenter w100">
                                <ButtonLivret
                                    type={'icone'}
                                    label={<IndeterminateCheckBoxIcon />}
                                    tooltip={'Suppimer un champs'}
                                    className={css.cVert}
                                    onClick={() => deleteChamps(dataEditModule, +dataEditModule[index].idDiscipline)}
                                />
                                <div className="w100">
                                    <div>
                                        <label className="mr2">Titre:</label>
                                        <span>{dataEditModule[index].titre}</span>
                                    </div>
                                    <div>
                                        <label className="mr2 fontSmallGrey">Description:</label>
                                        <span className="fontSmallGrey">{dataEditModule[index].description}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>,
                );
                update(titre, dataEditModule[index].titre);
                update(description, dataEditModule[index].description);
            }
            setAddInput(element);
            setReloadView(!reloadView);
        } else {
            element = (
                <div className="flex mt1">
                    <div className="flex directionColumn w40">
                        <label className="mr2">Titre:</label>
                        <Input
                            className={[css.w80, css.ml30px, css.mt1]}
                            value={dataInput[variableTitre]}
                            onChange={(event) => update(variableTitre, event.target.value)}
                        />
                    </div>
                    <br />
                    <div className="flex directionColumn w40">
                        <label className="mr2">Description:</label>
                        <TextareaAutosize
                            className={css.textareaML30pxW80MB1emMT0}
                            rowsMin={2}
                            value={dataInput[variableDescription]}
                            onChange={(event) => update(variableDescription, event.target.value)}
                        />
                    </div>
                    <br />
                </div>
            );
            setAddInput([...addInput, element]);
        }
    }

    function createModule() {
        const critereData = () => {
            let element = [];
            for (let elem = 0; elem < addInput.length; elem++) {
                const critere = {
                    idDiscipline: elem + 1,
                    titre: dataInput[`Titre${elem}`],
                    description: dataInput[`Description${elem}`],
                    type: 'textarea',
                    valeur: '',
                };
                element.push(critere);
            }
            return element;
        };
        const data = {
            idCritere: idCritere ? idCritere : props.data.discipline.length + 1,
            titre: dataInput.Titre,
            critere: dataInput.Critere,
            donnees: {
                criteres: critereData(),
            },
        };
        props.addModule(data);
        setDataInput({
            Titre: '',
            Critere: '',
        });
        setAddInput([]);
        props.editModule(null);
        setIdCritere();
    }
    function view() {
        return (
            <div>
                <ButtonLivret className={css.mb2} type={'button'} label={'Ajouter le module'} onClick={() => createModule()} />
                <div className="flex mt1">
                    <div className="w50">
                        <label>Titre:</label>
                        <Input
                            value={dataInput.Titre}
                            className={[css.ml30px, css.w50, css.nomargin]}
                            onChange={(event) => update('Titre', event.target.value)}
                        />
                        <div className="flex mt1">
                            <h5>Discipline:</h5>
                            <ButtonLivret
                                type={'icone'}
                                label={<AddBoxIcon />}
                                tooltip={'Ajouter une discipline'}
                                onClick={() => addElement()}
                                className={css.cVert}
                            />
                        </div>
                        {addInput}
                    </div>
                    <div className="w50">
                        <div>
                            <h4 className="nomargin">Critères d'acquisition : </h4>
                            <span className="fontSmallGrey">Séparer les critères par des sauts de ligne</span>
                        </div>
                        <TextareaAutosize
                            value={dataInput.Critere}
                            className={css.textareaML30pxW80MB1emMT0}
                            rowsMin={5}
                            onChange={(event) => update('Critere', event.target.value)}
                        />
                    </div>
                </div>
                <h2 className="textCenter">Visuel final</h2>
                <hr></hr>
            </div>
        );
    }
    useEffect(() => {
        if (idModule) {
            setDataInput({
                Titre: props.data.discipline[+idModule - 1].titre,
                Critere: props.data.discipline[+idModule - 1].critere,
            });
            addElement(props.data.discipline[+idModule - 1].donnees.criteres);
            setIdCritere(props.data.discipline[+idModule - 1].idCritere);
        }
    }, [idModule]);
    useEffect(() => {
        view();
    }, [reloadView]);
    return view();
};
function mapDispatchToProps(dispatch) {
    return {
        addModule(module) {
            dispatch({ type: 'ModelDiscipline', module });
        },
        editModule(idModule) {
            dispatch({ type: 'editModule', idModule });
        },
    };
}
export default connect(null, mapDispatchToProps)(ModuleDisciplineCreate);
