/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Vijaya Srikar PORALLA
 *   @author Viaud Benjamin
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableRow, IconButton, Dialog, DialogContent, DialogContentText, Paper } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Container from '../../components/atoms/Container/container';
import { GET, POST, DELETE } from '../../components/Utils/requete';
import PaginationTable from '../../components/organism/Table/Pagination';
import TableRecherche from '../../components/organism/Table/TableRecherche';
import TableHeadCustom from '../../components/organism/Table/TableHead';
import setData from '../../components/organism/Table/setDataTableau';
import SelectSimple from '../../components/organism/SelectSimple/SelectSimple';
import ListeButton from '../../components/atoms/ButtonCustom';
import Contact from '../../components/organism/Contact/Contact';
import ExportCSV from '../../components/organism/ExportTableau/ExportCSV';
import ExportExcel from '../../components/organism/ExportTableau/exportExcel';
import CustomTooltips from '../../components/organism/Tooltip/customTooltip';
import SelectMultiple from '../../components/organism/SelectMultiple/SelectMultiple';
import InfoAgent from '../../components/organism/InfoAgent/infoAgent';
import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';
import ButtonCustom from '../../components/atoms/ButtonCustom';
import creationTableauFiltre from '../../components/Utils/createTableauFiltre';
import { testDroit } from '../../components/Utils/testDroit';

import { tableHead, agentActif, tableHeadExport } from './consts';
import * as fonctionAgent from '../../fonctionsRoles/agents';

const ListeAgent = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [dataAgent, setDataAgent] = useState([]);
    const history = useHistory();
    const filtreSimple = useSelector((state) => state.SelectSimple);
    const refreshSimple = useSelector((state) => state.SelectSimple.refreshSimple);
    const filtreMultiple = useSelector((state) => state.SelectMultiple);
    const refreshMultiple = useSelector((state) => state.SelectMultiple.refreshMultiple);
    const css = useStylesMaterielUIGlobal();
    const [open, setOpen] = useState(false);
    const [delIdAgent, setDelIdAgent] = useState();
    const [selected, setSelected] = useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const [mailSelect, setSelectMail] = useState([]);
    const [mail, setMail] = useState([]);
    const [filtreSite, setFiltreSite] = useState([]);
    const [listeGroupeRole, setListeGroupeRole] = useState([]);
    const handleClick = (name, email) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
        setSelectMail([...mailSelect, email]);
    };
    function Icone(idUtilisateur) {
        const usurper = function () {
            if (testDroit(user[usurpation].roleFonction, fonctionAgent.Usurpation_agent)) {
                return (
                    <>
                        <CustomTooltips title="Usurper l'Agent">
                            <IconButton
                                onClick={() => {
                                    usurpationUser(idUtilisateur.idUtilisateur, 0);
                                }}
                                color="secondary"
                                className={[css.floatRight, css.p0, css.ml10px]}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </CustomTooltips>
                    </>
                );
            } else {
                return <></>;
            }
        };
        if (testDroit(user[usurpation].roleFonction, fonctionAgent.Suppression_agent)) {
            return (
                <>
                    <CustomTooltips title={"Supprimer l'Agent"}>
                        <IconButton
                            onClick={() => {
                                setDelIdAgent(idUtilisateur.idUtilisateur);
                                setOpen(true);
                            }}
                            color="secondary"
                            className={[css.floatRight, css.p0, css.ml10px]}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    </CustomTooltips>
                    {usurper()}
                </>
            );
        } else {
            return <></>;
        }
    }
    function BoutonNewAgent() {
        if (testDroit(user[usurpation].roleFonction, fonctionAgent.Ajout_nouvel_agent)) {
            return (
                <div className="flex w100 flexEnd">
                    <Link to={'/fiche-agent/0'}>
                        <ListeButton variant="outlined" size="small" color="primary">
                            <PersonAddIcon />
                            Créer un agent
                        </ListeButton>
                    </Link>
                </div>
            );
        } else {
            return <></>;
        }
    }
    function SelectionActivite() {
        if (testDroit(user[usurpation].roleFonction, fonctionAgent.Filtrage_liste)) {
            return (
                <>
                    <SelectSimple
                        tableau={agentActif}
                        indice={'Actif'}
                        inputLabel={'Activité'}
                        classe={[css.mr1, css.mb2, css.w30, css.xs_w100, css.xs_mb5]}
                    ></SelectSimple>
                    <SelectMultiple
                        tableau={listeGroupeRole}
                        inputLabel={'Roles'}
                        indice={'roles'}
                        classe={[css.ml2, css.mr1, css.w45, css.xs_w90, css.mb2]}
                    ></SelectMultiple>{' '}
                    <SelectMultiple
                        tableau={filtreSite}
                        inputLabel={'Sites'}
                        indice={'Site'}
                        classe={[css.ml2, css.mr1, css.w45, css.xs_w90, css.mb2]}
                        disabled={testDroit(user[usurpation].roleFonction, fonctionAgent.Trie_site)}
                    ></SelectMultiple>
                </>
            );
        } else {
            return <></>;
        }
    }
    async function recupDataAgent() {
        const data = {
            role: filtreMultiple.roles ? filtreMultiple.roles : null,
            site: filtreMultiple.Site ? filtreMultiple.Site : null,
        };
        // eslint-disable-next-line eqeqeq
        if (filtreSimple.Actif != 2) {
            data.actif = filtreSimple.Actif === 1 ? 1 : 0;
        }
        const response = await POST('/api/utilisateursAdministratifs/list/', data);
        setDataAgent(response.data);
        const mailsAgent = [];
        for (let index = 0; index < response.data.length; index++) {
            mailsAgent.push(response.data[index].email);
        }
        setMail(mailsAgent);
    }

    async function recupFiltreSite() {
        let response = await GET('/api/sites/liste');
        setFiltreSite(response.data);
        response = await GET(`/api/gestionRole/listeGroupe`);
        setListeGroupeRole(creationTableauFiltre(response.data, 'idGroupe', 'libelleGroupe'));
    }
    async function usurpationUser(id, isR) {
        const response = await GET(`/api/usurpation?fk_id=${id}&isResponsable=${isR}`);
        props.usurpationR(response.data);
        history.push('/accueil');
    }
    async function supprimerAgent(idUtilisateur) {
        await DELETE(`/api/utilisateursAdministratifs/${idUtilisateur}`);
        recupDataAgent();
        setOpen(false);
    }

    useEffect(() => {
        props.addNomPage('Liste des agents');
        props.filtre([{ Actif: 1 }]);
        if (filtreSite.length === 0) {
            recupFiltreSite();
        }
        props.filtreMultiple([{ Site: [] }]);
        if (testDroit(user[usurpation].roleFonction, fonctionAgent.Trie_site)) {
            const siteAtenne = [];
            for (let index = 0; index < user[usurpation].antennes.length; index++) {
                siteAtenne.push(user[usurpation].antennes[index].idSite);
            }
            props.filtreMultiple([{ Site: siteAtenne }]);
        }
    }, []);
    useEffect(() => {
        recupDataAgent();
    }, [refreshSimple, refreshMultiple]);

    return (
        <div className="listeContainer">
            <Container>
                <div className="flex alignICenter xs_directionColumnReverse">
                    <SelectionActivite />
                    <BoutonNewAgent />
                </div>
                <div className="flex alignICenter xs_w100">
                    <TableRecherche tableau={dataAgent} className={[css.w50, css.xs_w100]} indice={['nomPrenomUtilisateur', 'email', 'role']}></TableRecherche>
                    <div className="flex w100 flexEnd xs_w0">
                        {testDroit(user[usurpation].roleFonction, fonctionAgent.Envoi_email) ? (
                            <div>
                                <ButtonCustom
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setTimeout(() => {
                                            setSelected([]);
                                            setSelectMail([]);
                                        }, 1000);
                                    }}
                                    className={[css.xs_w100, css.xs_mb2]}
                                >
                                    <Contact email={mailSelect} lib="Email à la selection" />
                                </ButtonCustom>
                                <ButtonCustom variant="outlined" size="small" className={[css.xs_w100, css.xs_mb2]}>
                                    <Contact email={mail} lib="Email à tous" />
                                </ButtonCustom>
                            </div>
                        ) : null}
                        {testDroit(user[usurpation].roleFonction, fonctionAgent.Export_excel) ? (
                            <div>
                                <ExportCSV data={dataAgent} head={tableHeadExport} />
                                <ExportExcel data={dataAgent} head={tableHeadExport} nomFichier="Liste Agent" />
                            </div>
                        ) : null}
                    </div>
                </div>
                <div>
                    <Dialog
                        open={open}
                        onClose={() => {
                            setOpen(false);
                        }}
                        maxWidth="lg"
                    >
                        <DialogContent>
                            <DialogContentText>
                                <span className="mr2">Voulez-vous supprimer cet agent ?</span>
                                <div className="mt2 textCenter">
                                    <ListeButton
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        non
                                    </ListeButton>
                                    <ListeButton
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            supprimerAgent(delIdAgent);
                                        }}
                                        color="primary"
                                    >
                                        oui
                                    </ListeButton>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </div>
                <PaginationTable data={props.recherche.fn(dataAgent)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
                <TableContainer component={Paper}>
                    <Table id="table-to-xls">
                        <TableHeadCustom data={tableHead}></TableHeadCustom>
                        <TableBody id="testPDF" className={css.tbody}>
                            {setData(props.recherche.fn, dataAgent, props.Page[0].page, props.Page[0].rowsPerPage, props.orderState, props.orderByState).map(
                                (row, index) => {
                                    const icone = <Icone idUtilisateur={row.idUtilisateur} />;
                                    return (
                                        <TableRow
                                            key={row.nomPrenomUtilisateur}
                                            className={css.trbody}
                                            selected={isSelected(row.nomPrenomUtilisateur)}
                                            onClick={() => handleClick(row.nomPrenomUtilisateur, row.email)}
                                        >
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>
                                                    <span>Agents</span>
                                                    <span>{window.matchMedia('(max-width: 576px)').matches ? icone : null}</span>
                                                </div>
                                                {row.nomPrenomUtilisateur}
                                                <br />
                                                <InfoAgent
                                                    liste={true}
                                                    idUtilisateur={row.idUtilisateur}
                                                    idCours={fonctionAgent.Lien_cours_Liste_Agent}
                                                    idService={fonctionAgent.Lien_services_Liste_Agent}
                                                    idProfil={fonctionAgent.Lien_profil}
                                                />
                                                {window.matchMedia('(max-width: 576px)').matches ? null : icone}
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Fixe</div>
                                                <Contact telephone={row.telephoneFixe} />
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Mobile</div>
                                                <Contact mobile={row.telephoneMobile} />
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Email</div>
                                                <Contact email={row.email} />
                                            </TableCell>
                                            <TableCell className={css.td} component="th" scope="row">
                                                <div>Rôle</div>
                                                {row.role}
                                            </TableCell>
                                        </TableRow>
                                    );
                                },
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationTable data={props.recherche.fn(dataAgent)} page={props.Page[0].page} row={props.Page[0].rowsPerPage}></PaginationTable>
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        usurpationR(user) {
            dispatch({ type: 'USURPATION_UTILISATEUR', user });
        },
        filtreMultiple(filtreMultiple) {
            dispatch({ type: 'multiple', filtreMultiple });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ListeAgent);
