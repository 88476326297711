/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author viaud Benjamin
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { FormControl, Select, Checkbox, Button } from '@material-ui/core';

import { GET, POST } from '../../components/Utils/requete';
import CalendarCustom from '../../components/organism/Calendar/Calendar';

import { color } from '../../components/Utils/consts';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import * as fonctionPlanning from '../../fonctionsRoles/planning';

const PlanningSalle = (props) => {
    const css = useStylesMaterielUIGlobal();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [site, setSite] = useState([]);
    const [idSite, setIdSite] = useState();
    const [idSalle, setIdsalle] = useState([]);
    const [salles, setSalles] = useState([]);
    const [eventSalle, setEventSalle] = useState([]);
    async function planningBySalle() {
        const data = {
            fk_anneeScolaire: user.idAnneeScolaire,
            fk_site: idSite,
            fk_salle: idSalle,
        };
        const response = await POST('api/planning/salle', data, { redirectOnError: false });
        const tableauEvent = [];
        for (let index = 0; index < response.data.length; index++) {
            const color = salles.filter((e) => e.idSalle === response.data[index].idSalle);
            tableauEvent.push({ tableau: response.data[index], color: color[0].color });
        }
        setEventSalle(tableauEvent);
    }
    async function recupSite() {
        if (testDroit(user[usurpation].roleFonction, fonctionPlanning.liste_sites)) {
            const response = await GET('/api/utilisateursAdministratifs/' + user[usurpation].fk_id);
            if (response.data.antennes.length !== 0) {
                let sites = [];
                for (let index = 0; index < response.data.antennes.length; index++) {
                    const site = {
                        idSite: response.data.antennes[index].idSite,
                        libelleSite: response.data.antennes[index].libelleSite,
                    };
                    sites.push(site);
                }
                return setSite(sites);
            } else {
                const siteFiltre = await GET('/api/sites/liste?full=1&onlyVisible=1');
                return setSite(siteFiltre.data.filter((e) => e.libelleSite !== 'Hors-site'));
            }
        } else {
            const siteFiltre = await GET('/api/sites/liste?full=1&onlyVisible=1');
            return setSite(siteFiltre.data.filter((e) => e.libelleSite !== 'Hors-site'));
        }
    }
    async function recupSalles() {
        setEventSalle({ tableau: [], color: '' });
        setIdsalle([]);
        const dataSalle = {
            fk_site: [idSite],
        };
        const response = await POST('api/salles/liste', dataSalle);
        for (let index = 0; index < response.data.length; index++) {
            response.data[index].color = color[index];
        }
        setSalles(response.data);
    }
    function checkSalle(id) {
        if (idSalle.includes(id)) {
            const supprimerSalle = idSalle.filter((e) => e !== id);
            if (supprimerSalle.length === 0) {
                setEventSalle({ tableau: [], color: '' });
            }
            setIdsalle(supprimerSalle);
        } else {
            setIdsalle((prevState) => [...prevState, id]);
        }
    }
    function CheckBoxSalle() {
        return (
            <div className="grid grid6Column">
                {salles.map((row, index) => (
                    <div>
                        <Checkbox key={index} checked={idSalle.includes(row.idSalle)} onChange={() => checkSalle(row.idSalle)} />
                        <span className="ml1 p1_5 radius10" style={{ backgroundColor: `${color[index]}`, color: '#fff' }}>
                            {row.libelleSalle}
                        </span>
                    </div>
                ))}
            </div>
        );
    }
    function toutSelection() {
        const id = [];
        if (idSalle.length === salles.length) {
            setIdsalle(id);
            setEventSalle({ tableau: [], color: '' });
        } else {
            for (let index = 0; index < salles.length; index++) {
                id.push(salles[index].idSalle);
            }
            setIdsalle(id);
        }
    }
    useEffect(() => {
        props.addNomPage('Planning des salle');
        props.removeFiltre();
        recupSite();
    }, []);
    useEffect(() => {
        if (idSite) {
            recupSalles();
        }
    }, [idSite]);
    useEffect(() => {
        if (idSalle.length !== 0) {
            planningBySalle();
        }
    }, [idSalle]);
    return (
        <div className="listeContainer">
            <div className="flex mt2 mb2 spaceAround">
                <div className="flex w50 alignICenter">
                    <span>Site</span>
                    <FormControl className={[css.w50, css.ml2]}>
                        <Select
                            onChange={(event) => {
                                setIdSite(event.target.value);
                            }}
                        >
                            <option value={''}></option>
                            {site.map((elem, index) => {
                                return (
                                    <option key={index} value={elem.idSite}>
                                        {elem.libelleSite}
                                    </option>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>

                {eventSalle.length !== 0 ? (
                    <Button variant="outlined" size="small" color="primary" onClick={() => toutSelection()}>
                        {idSalle.length === salles.length ? 'Tout desélectionner' : 'Tout sélectionner'}
                    </Button>
                ) : null}
            </div>

            {CheckBoxSalle()}
            <CalendarCustom tableau={eventSalle} className="mt2 " type="planning" />
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        removeFiltre() {
            dispatch({ type: 'removeFiltre' });
        },
    };
}

export default connect(null, mapDispatchToProps)(PlanningSalle);
