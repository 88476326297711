/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Page d'edition d'information'
 *
 * @copyright Horizon-Pharma
 */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { TextField, Button, Checkbox } from '@material-ui/core';

import { POST, GET } from '../../components/Utils/requete';
import Container from '../../components/atoms/Container/container';
import SelectMultiple from '../../components/organism/SelectMultiple/SelectMultiple';
import dateSave from '../../components/Utils/dateSave';
import CustomTooltips from '../../components/organism/Tooltip/customTooltip';
import creationTableauFiltre from '../../components/Utils/createTableauFiltre';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import * as fonctionInformation from '../../fonctionsRoles/information';

function EditionInformation(props) {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const reducerFiltreMultiple = useSelector((state) => state.SelectMultiple);
    let { id } = useParams();
    const css = useStylesMaterielUIGlobal();
    const [informationBDD, setInformationBDD] = useState('');
    const [message, setMessage] = useState('');
    const [checkBox, setCheckBox] = useState(false);
    const [titre, setTitre] = useState('');
    const [listeGroupeRole, setListeGroupeRole] = useState([]);
    const [destinataireResponsable, setDestinataireResponsable] = useState(false);
    async function destinataire() {
        const response = await GET(`/api/gestionRole/listeGroupe`);
        setListeGroupeRole(creationTableauFiltre(response.data, 'idGroupe', 'libelleGroupe'));
    }
    async function saveText() {
        const data = {
            idInformation: id === '0' ? null : id,
            fk_utilisateur: user.user.fk_id,
            destinataires: destinataireResponsable ? 'Responsable' : null,
            date: dateSave(),
            visible: checkBox === true ? 1 : 0,
            titre: titre,
            message: message,
            groupe: reducerFiltreMultiple.roles,
        };
        await POST(`/api/informations/${id}`, data, { redirectOnError: false });
        props.notification({
            message:
                checkBox === true
                    ? 'ce message a été enregistré et rendu visible'
                    : "Ce message a été enregistré comme brouillon. Le message ne sera pas visible sur la page d'accueil",
            status: true,
        });
    }
    function nouveauMessage() {
        props.removeFiltre();
        setInformationBDD('');
        setTitre('');
    }
    async function recupData() {
        const response = await GET(`/api/informations/${id}`);
        setInformationBDD(response.data.message);
        if (response.data.destinataires && response.data.destinataires.includes('Responsable')) setDestinataireResponsable(true);
        if (response.data.groupe) props.filtreMultiple([{ roles: response.data.groupe }]);
        setTitre(response.data.titre);
        props.addNomPage('Edition Information - ' + response.data.titre);
        if (response.data.visible === 1) setCheckBox(true);
    }
    useEffect(() => {
        if (id !== '0') recupData();
        destinataire();
    }, []);
    return (
        <Container>
            <div className="xs_flex xs_directionColumn">
                <SelectMultiple
                    tableau={listeGroupeRole}
                    inputLabel={'Destinataire'}
                    indice={'roles'}
                    classe={[css.ml2, css.mr2, css.w25, css.xs_w90, css.mb2]}
                ></SelectMultiple>
                <TextField
                    value={titre}
                    label="Titre"
                    className={[css.ml2, css.w50, css.xs_w90]}
                    onChange={(event) => {
                        setTitre(event.target.value);
                    }}
                />
            </div>
            <div className="ml2  alignICenter">
                <label>A destination des responsables</label>
                <CustomTooltips title="Ce message sera rendu visible sur la page d'accueil">
                    <Checkbox
                        checked={destinataireResponsable}
                        onChange={() => {
                            setDestinataireResponsable(!destinataireResponsable);
                        }}
                    />
                </CustomTooltips>
            </div>
            <div className="m5">
                <SunEditor
                    onChange={(elem) => {
                        setMessage(elem);
                    }}
                    setContents={informationBDD}
                    height="300"
                    setOptions={{
                        buttonList: [
                            ['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link', 'image'],
                        ],
                    }}
                />
            </div>
            <div className="ml2  alignICenter">
                <label>Visible</label>
                <CustomTooltips title="Ce message sera rendu visible sur la page d'accueil">
                    <Checkbox
                        checked={checkBox}
                        onChange={() => {
                            setCheckBox(!checkBox);
                        }}
                    />
                </CustomTooltips>
            </div>
            <div className="m2 xs_flex xs_directionColumn">
                {testDroit(user[usurpation].roleFonction, fonctionInformation.Modification_information) ? (
                    <Link to="/liste-informations">
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className={[css.xs_w90, css.xs_ml2]}
                            onClick={() => {
                                saveText();
                            }}
                        >
                            Enregistrer
                        </Button>
                    </Link>
                ) : null}
                {testDroit(user[usurpation].roleFonction, fonctionInformation.Creation_information) ? (
                    <Link to="/edition-information/0">
                        <Button
                            variant="outlined"
                            size="small"
                            className={[css.cVert, css.borderCvert, css.m2, css.xs_w90]}
                            onClick={() => {
                                nouveauMessage();
                            }}
                        >
                            Nouveau Message
                        </Button>
                    </Link>
                ) : null}
                <Link to="/liste-informations">
                    <Button variant="outlined" className={[css.xs_w90, css.xs_ml2]} color="secondary" size="small">
                        Retour
                    </Button>
                </Link>
            </div>
        </Container>
    );
}
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        removeFiltre() {
            dispatch({ type: 'removeFiltreMultiple' });
        },
        filtreMultiple(filtreMultiple) {
            dispatch({ type: 'multiple', filtreMultiple });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(EditionInformation);
