/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Paper, Card, CardContent, Typography } from '@material-ui/core';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import Container from '../../components/atoms/Container/container';
import { GET } from '../../components/Utils/requete';
import CustomTooltips from '../../components/organism/Tooltip/customTooltip';
import { testDroit } from '../../components/Utils/testDroit';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

import { Mofication_informations } from '../../fonctionsRoles/acceuil';

const OngletAcceuil = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [dataInformation, setDataInformation] = useState([]);
    const css = useStylesMaterielUIGlobal();
    async function getInformation() {
        const response = await GET(`/api/informations/list?lecture=true&visible=1&groupe=${user[usurpation].groupe}`, undefined, { redirectOnError: false });
        setDataInformation(response.data);
    }

    function InformationHeader(props) {
        const { information } = props;
        return (
            <>
                <Link to={`/edition-information/${information.idInformation}`}>
                    <CustomTooltips title="Éditer l'information">
                        <FindInPageIcon className={[css.floatRight, css.mt10px]} />
                    </CustomTooltips>
                </Link>
                <Typography className={css.fontSize14px} color="textSecondary" gutterBottom>
                    Auteur : {information.nomPrenomUtilisateur}
                </Typography>
                <Typography className={css.fontSize14px} color="textSecondary" gutterBottom>
                    Date : {information.date}
                </Typography>
                <Typography className={css.fontSize14px} color="textSecondary" gutterBottom>
                    A destination de : {information.destinataires}
                </Typography>
            </>
        );
    }

    function Information(props) {
        const { information } = props;

        let header = '';
        if (testDroit(user[usurpation].roleFonction, Mofication_informations)) {
            header = <InformationHeader information={information} />;
        }

        return (
            <>
                <Card className={[css.ml10, css.mb2, css.xs_nomargin, css.xs_mb5]}>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            {information.titre}
                        </Typography>
                        {header}
                        <div
                            dangerouslySetInnerHTML={{
                                __html: information.message,
                            }}
                            className="leading100"
                        ></div>
                    </CardContent>
                </Card>
            </>
        );
    }
    useEffect(() => {
        getInformation();
        props.addNomPage(`Accueil`);
    }, []);

    return (
        <Paper className={[css.wMax875, css.BcGrisClair, css.xs_nomargin, css.marginAuto]} elevation={0}>
            <Container>
                {dataInformation.map((row, index) => {
                    return <Information information={row} key={index} />;
                })}
            </Container>
        </Paper>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
    };
}
export default connect(null, mapDispatchToProps)(OngletAcceuil);
